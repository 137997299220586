import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import store from 'redux-store'
import { dataTypes, FORM } from '@adalo/constants'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import {
  getCurrentAppId,
  updateObject,
  getComponent,
} from 'ducks/editor/objects'
import {
  getTableOptions,
  getFormReferenceOptions,
  getLibraryBindingSuggestions,
  getLibraryAllBinding,
} from 'ducks/recommender'
import { openAccordion, closeAccordion } from 'ducks/accordions'
import { getDatasourcesObject, getOrderedFields } from 'ducks/apps/datasources'
import { getApp, getAppBranding } from 'ducks/apps'
import {
  getActiveState,
  getTrialState,
  isFeatureEnabled,
} from 'ducks/organizations'
import { generateHandleTrial } from 'hooks/useHandleTrialOrUpgrade'
import { getCollection } from 'utils/datasources'
import { singularize } from 'utils/strings'
import { calculateLayout } from 'utils/forms'
import { getFontFamilyOptions } from 'utils/type'
import { defaults } from 'utils/objects'
import { verifyXanoApp } from 'utils/externalDatabases'
import { GroupedAccordion } from 'components/Shared/Accordion'
import EmptyState from 'components/Shared/EmptyState'
import Icon from 'components/Shared/Icon'
import ToggleButton from 'components/Shared/Forms/ToggleButton'
import StylesAccordion from 'components/Shared/StylesAccordion'
import MenuControl from '../Libraries/MenuControl'
import NewTableOption from '../Libraries/NewTableOption'
import SliderControl from '../Libraries/SliderControl'
import GenericInspectRow from '../GenericRow'
import TextControl from '../TextControl'
import { ShadowControl as DefaultShadowControl } from '../DefaultShadowControl'
import ColorDropdown from '../ColorDropdown'
import { referenceComparator } from './utils'
import HiddenField from './HiddenField'
import { SecondaryButton, SubmitButton } from './FormButtons'
import SortableFieldItemsList from './SortableFieldItemsList'
import { THEMES, BEFORE, START_TRIAL, UPGRADE } from '../../../../constants'

import './FormInspect.scss'

const FORM_ACCORDION_GROUP = 'form-inspect'
const FORM_FIELD_ACCORDION_GROUP = 'form-inspect-field'

const secondaryButtonDefaults = defaults[FORM].secondaryButton

/**
 * @typedef {import('./types').FormInspectProps} Props
 */

class FormInspect extends Component {
  /**
   *
   * @param {Partial<import('utils/responsiveTypes').EditorObject>} changes
   */
  update = changes => {
    /** @type {Props} */
    const { object, app, updateObject } = this.props

    if (app?.magicLayout === true) {
      delete changes.height
    }

    updateObject(object.id, changes)
  }

  handleChangeReference = ({ reference }) => {
    /** @type {Props} */
    const { object, datasources } = this.props
    let { collection, submitButton, fields, height } = object

    submitButton = {
      ...submitButton,
      text: this.getSubmitButtonText(collection, reference),
    }

    const datasource = datasources[collection.datasourceId]
    const authTable = datasource.auth && datasource.auth.table

    if (authTable === collection.tableId) {
      fields = this.getFields(collection, reference)
      height = this.getHeight({ fields })
    }

    this.update({ reference, submitButton, fields, height })
  }

  handleChangeTable = opts => {
    /** @type {Props} */
    const { datasources } = this.props

    // When a new table is created, opts should return with a source
    // We need to set the opts.collection before the undefined check
    if (opts.source) {
      const { datasourceId, tableId } = opts.source.source
      opts.collection = { datasourceId, tableId }
    }

    // if true, should be a new table
    if (
      opts.collection === undefined ||
      opts.collection.datasourceId === undefined
    ) {
      return
    }

    /** @type {Props} */
    let { object, reference, collection } = this.props

    // TODO(enzo): handle secondary button here too
    let { submitButton } = object

    const datasource = datasources[opts.collection.datasourceId]
    const authTable = datasource.auth && datasource.auth.table

    if (!reference || opts.collection !== collection) {
      if (authTable === opts.collection.tableId) {
        reference = 'login'
      } else {
        reference = 'new'
      }
    }

    const fields = this.getFields(opts.collection, reference)
    const hiddenFields = this.getHiddenFields(opts.collection)
    const height = this.getHeight({ fields })

    submitButton = {
      ...submitButton,
      text: this.getSubmitButtonText(opts.collection, reference),
    }

    this.update({
      fields,
      hiddenFields,
      height,
      reference,
      submitButton,
      collection: opts.collection,
    })
  }

  getSubmitButtonText = (collection, reference) => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]
    let table = null

    if (collection.tableId) {
      table = datasource.tables[collection.tableId]
    } else {
      table = datasource.collections[collection.collectionId]
    }

    const tableName = singularize(table.name)

    let result = `Update ${tableName}`

    if (reference === 'new') {
      result = `Create ${tableName}`
    } else if (reference === 'login') {
      result = 'Login'
    } else if (reference === 'signup') {
      result = 'Signup'
    }

    return result.toUpperCase()
  }

  getHeight = changes => {
    /** @type {Props} */
    const { object } = this.props
    const layout = calculateLayout({ ...object, ...changes })

    return layout.height
  }

  getFields = (collection, reference) => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const result = []

      let fields = getOrderedFields(table)

      if (reference === 'login') {
        fields = ['email', 'password']
      }

      for (const fieldId of fields) {
        const field = table.fields[fieldId]

        if (typeof field.type !== 'string') {
          continue
        }

        result.push(this.getField(fieldId, collection))
      }

      return result
    } else {
      // API
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return []
    }
  }

  getHiddenFields = collection => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const result = []

      for (const fieldId of getOrderedFields(table)) {
        const field = table.fields[fieldId]

        if (field.type.type !== 'belongsTo') {
          continue
        }

        result.push(this.getHiddenField(fieldId, collection))
      }

      return result
    } else {
      // API
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return []
    }
  }

  getField = (fieldId, collection) => {
    /** @type {Props} */
    const { object, datasources } = this.props

    if (!collection) {
      collection = object.collection
    }

    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const field = table.fields[fieldId]

      const verb =
        field.type === dataTypes.DATE || field.type.type === 'belongsTo'
          ? 'Select'
          : 'Enter'

      let placeholder = `${verb} ${(field.name || '').toLowerCase()}...`
      let requiredText = 'This field is required.'
      let icon

      if (field.type === dataTypes.LOCATION) {
        placeholder = 'Search by name or address...'
        requiredText = 'Please select a valid location from the dropdown menu.'
        icon = 'location_on'
      }

      return {
        fieldId,
        label: field.name,
        placeholder,
        type: field.type,
        required: true,
        requiredText,
        icon,
      }
    } else {
      // API
      // TODO: implement properly
      throw new Error('APIs not yet supported')
    }
  }

  getHiddenField = (fieldId, collection) => {
    /** @type {Props} */
    const { appId, componentId, object, datasources } = this.props

    if (!collection) {
      collection = object.collection
    }

    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const field = table.fields[fieldId]
      let value

      if (field.type.type === 'belongsTo') {
        const state = store.getState()

        const options = getLibraryBindingSuggestions(
          state,
          appId,
          componentId,
          object.id,
          [field.type]
        )()

        const option = options.filter(opt => opt && opt.value)[0]

        value = option && option.value
      }

      return {
        fieldId,
        value,
        type: field.type,
      }
    } else {
      // API
      // TODO: implement properly
      throw new Error('APIs not yet supported')
    }
  }

  getAddHiddenFieldOptions = () => {
    return this.getAddFieldOptions({ hidden: true })
  }

  getAddFieldOptions = (opts = {}) => {
    /** @type {Props} */
    const {
      object,
      datasources,
      isLocationEnabled,
      trialState,
      isPaidOrg,
      handleTrial,
      appId,
    } = this.props

    let { fields, hiddenFields, collection } = object

    if (opts.hidden) {
      fields = hiddenFields
    }

    fields = fields || []

    const datasource = datasources[collection.datasourceId]
    const existingFields = fields.map(f => f.fieldId)

    const result = []

    // API
    if (!collection.tableId) {
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return result
    }

    // Table
    const table = datasource.tables[collection.tableId]

    for (const fieldId of getOrderedFields(table)) {
      const field = table.fields[fieldId]

      // Exclude many-to-many relationships
      if (
        existingFields.includes(fieldId) ||
        field?.type?.type === 'manyToMany'
      ) {
        continue
      }

      // Disable and show an upsell CTA for location fields
      if (field?.type === dataTypes.LOCATION && !isLocationEnabled) {
        const hoverContent =
          trialState === BEFORE && !isPaidOrg ? START_TRIAL : UPGRADE

        result.push({
          label: field.name,
          locked: true,
          rightIcon: <Icon type="lock-small" small />,
          onClick: () => handleTrial(trialState, isPaidOrg, appId),
          hoverContent,
        })

        continue
      }

      result.push({
        label: field.name,
        value: opts.hidden
          ? this.getHiddenField(fieldId, collection)
          : this.getField(fieldId, collection),
      })
    }

    return result
  }

  // Create, update, delete fields

  handleAddField = field => {
    /** @type {Props} */
    const { object, openAccordion, datasources } = this.props
    let { fields, hiddenFields } = object

    if (field.type.type === 'belongsTo') {
      const { datasourceId, tableId } = field.type
      const datasource = datasources[datasourceId]
      const table = datasource.tables[tableId]

      const labelField = Object.keys(table.fields).filter(
        f => table.fields[f].isPrimaryField
      )[0]

      field = {
        ...field,
        binding: getLibraryAllBinding(datasourceId, tableId, { labelField }),
      }
    }

    hiddenFields = hiddenFields.filter(f => f.fieldId !== field.fieldId)
    fields = fields.concat([field])
    const height = this.getHeight({ fields })

    openAccordion(FORM_FIELD_ACCORDION_GROUP, field.fieldId)
    this.update({ fields, hiddenFields, height })
  }

  handleChangeField = (index, field) => {
    /** @type {Props} */
    const { object } = this.props
    let { fields } = object

    fields = fields.slice()
    fields.splice(index, 1, { ...fields[index], ...field })

    const height = this.getHeight({ fields })

    this.update({ fields, height })
  }

  handleDeleteField = index => {
    /** @type {Props} */
    const { object } = this.props
    let { fields } = object

    fields = fields.slice()
    fields.splice(index, 1)
    const height = this.getHeight({ fields })

    this.update({ fields, height })
  }

  // Create, update, delete hidden fields

  handleAddHiddenField = field => {
    /** @type {Props} */
    const { object, openAccordion } = this.props
    let { fields } = object
    let hiddenFields = object.hiddenFields || []

    fields = fields.filter(f => f.fieldId !== field.fieldId)
    hiddenFields = hiddenFields.concat([field])
    const height = this.getHeight({ fields })

    openAccordion(FORM_FIELD_ACCORDION_GROUP, field.fieldId)
    this.update({ hiddenFields, fields, height })
  }

  handleChangeHiddenField = (index, field) => {
    /** @type {Props} */
    const { object } = this.props
    let { hiddenFields } = object

    hiddenFields = hiddenFields.slice()
    hiddenFields.splice(index, 1, { ...hiddenFields[index], ...field })

    this.update({ hiddenFields })
  }

  handleDeleteHiddenField = index => {
    /** @type {Props} */
    const { object } = this.props
    let { hiddenFields } = object

    hiddenFields = hiddenFields.slice()
    hiddenFields.splice(index, 1)

    this.update({ hiddenFields })
  }

  handleUpdateSubmit = (value, actionChanges) => {
    /** @type {Props} */
    const { object } = this.props
    let { actions, submitButton } = object

    submitButton = { ...submitButton, ...value }
    const height = this.getHeight({ submitButton })
    const updatedActions = { ...actions, ...actionChanges }
    const update = { submitButton, height, actions: updatedActions }

    this.update(update)
  }

  handleUpdateSecondaryButton = (value, actionChanges) => {
    /** @type {Props} */
    const { object } = this.props
    let { actions, secondaryButton } = object

    if (value?.enabled && typeof secondaryButton === 'undefined') {
      // If the secondaryButton property doesn't exist, we need to set it to the default values
      secondaryButton = { ...secondaryButtonDefaults, enabled: true }
    } else {
      secondaryButton = { ...secondaryButton, ...value }
    }
    const height = this.getHeight({ secondaryButton })
    const updatedActions = { ...actions, ...actionChanges }
    const update = { secondaryButton, height, actions: updatedActions }

    this.update(update)
  }

  getCollectionName = () => {
    /** @type {Props} */
    const { object, datasources } = this.props
    const { collection } = object

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    return datasourceCollection.name
  }

  handleSortFields = ({ oldIndex, newIndex }) => {
    /** @type {Props} */
    const { object } = this.props

    const fields = object.fields.slice()
    const field = fields[oldIndex]
    fields.splice(oldIndex, 1)
    fields.splice(newIndex, 0, field)

    this.update({ fields })
  }

  renderFields() {
    /** @type {Props} */
    const { object, datasources, appId, componentId } = this.props
    let { collection, fields, hiddenFields, reference } = object

    fields = fields || []
    hiddenFields = hiddenFields || []

    if (!collection || !reference) {
      return null
    }

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    if (!datasource || !datasourceCollection) {
      return null
    }

    return (
      <GroupedAccordion
        className="library-inspect-accordion"
        group={FORM_ACCORDION_GROUP}
        title="Fields"
        renderChildren={() => (
          <div>
            <div className="form-inspect-fields-section">
              <p className="form-inspect-fields-title">Form Fields</p>
              {fields.length === 0 ? (
                <EmptyState>No Visible Fields</EmptyState>
              ) : (
                <SortableFieldItemsList
                  items={fields}
                  collection={datasourceCollection}
                  datasource={datasource}
                  appId={appId}
                  componentId={componentId}
                  object={object}
                  onChange={this.handleChangeField}
                  onDelete={this.handleDeleteField}
                  onSortEnd={this.handleSortFields}
                  distance={5}
                />
              )}
              <MultiMenuTrigger
                className="form-inspect-add-field"
                menu={this.getAddFieldOptions}
                onSelect={this.handleAddField}
                menuTheme={THEMES.DATA}
              >
                <Icon type="plus" />
                <span>Add Visible Field</span>
              </MultiMenuTrigger>
            </div>
            <div className="form-inspect-fields-section">
              <p className="form-inspect-fields-title">Set Automatically</p>
              {hiddenFields.length === 0 ? (
                <EmptyState>No Automatic Fields</EmptyState>
              ) : (
                hiddenFields.map((field, i) => (
                  <HiddenField
                    key={field.fieldId}
                    field={field}
                    index={i}
                    object={object}
                    appId={appId}
                    componentId={componentId}
                    collection={datasourceCollection}
                    datasource={datasource}
                    onChange={this.handleChangeHiddenField}
                    onDelete={this.handleDeleteHiddenField}
                  />
                ))
              )}
              <MultiMenuTrigger
                className="form-inspect-add-field"
                menu={this.getAddHiddenFieldOptions}
                onSelect={this.handleAddHiddenField}
              >
                <Icon type="plus" />
                <span>Add Automatic Field</span>
              </MultiMenuTrigger>
            </div>
            <StylesAccordion renderChildren={this.renderFieldStyles} />
          </div>
        )}
      />
    )
  }

  handleChangeFieldStyles = namespace => changes => {
    /** @type {Props} */
    const { object } = this.props
    let { fieldStyles } = object
    fieldStyles = fieldStyles || {}

    const prevValue = namespace ? fieldStyles[namespace] || {} : fieldStyles

    let styles = { ...prevValue, ...changes }

    if (namespace) {
      styles = { ...fieldStyles, [namespace]: styles }
    }

    const height = this.getHeight({ fieldStyles: styles })
    this.update({ fieldStyles: styles, height })
  }

  getFieldStyles = namespace => {
    /** @type {Props} */
    const { object } = this.props
    let { fieldStyles } = object

    if (!fieldStyles) {
      fieldStyles = {}
    }

    if (namespace) {
      return fieldStyles[namespace] || {}
    }

    return fieldStyles
  }

  renderFieldStyles = () => {
    /** @type {Props} */
    const { branding } = this.props

    return (
      <div>
        <GenericInspectRow className="default-font-control" key="fontControl">
          <TextControl
            gray
            type="number"
            name="fontSize"
            value={this.getFieldStyles('inputs').fontSize ?? 16}
            onChange={this.handleChangeFieldStyles('inputs')}
            title="Inputs Size"
            minValue={8}
            maxValue={36}
          />
          <TextControl
            gray
            type="number"
            name="fontSize"
            value={this.getFieldStyles('labels').fontSize ?? 14}
            onChange={this.handleChangeFieldStyles('labels')}
            title="Labels Size"
            minValue={8}
            maxValue={36}
          />
        </GenericInspectRow>
        <MenuControl
          displayName="Inputs Font Family"
          name="fontFamily"
          value={this.getFieldStyles('inputs').fontFamily}
          onChange={this.handleChangeFieldStyles('inputs')}
          options={getFontFamilyOptions(branding)}
          className="font-family-control"
        />
        <MenuControl
          displayName="Labels Font Family"
          name="fontFamily"
          value={this.getFieldStyles('labels').fontFamily}
          onChange={this.handleChangeFieldStyles('labels')}
          options={getFontFamilyOptions(branding)}
          className="font-family-control"
        />
        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Inputs Color"
            value={this.getFieldStyles('inputs').color ?? '#000'}
            name="color"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
          <ColorDropdown
            label="Labels Color"
            value={this.getFieldStyles('labels').color ?? '#000'}
            name="color"
            onChange={this.handleChangeFieldStyles('labels')}
          />
        </GenericInspectRow>
        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Placeholder Color"
            value={this.getFieldStyles('inputs').placeholderColor ?? '#aaa'}
            name="placeholderColor"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
          <ColorDropdown
            label="Fill Color"
            value={this.getFieldStyles('inputs').backgroundColor ?? '#fff'}
            name="backgroundColor"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
        </GenericInspectRow>
        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Accent Color"
            value={this.getFieldStyles('inputs').accentColor ?? '@secondary'}
            name="accentColor"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
          <ColorDropdown
            label="Error Color"
            value={this.getFieldStyles('inputs').errorColor ?? '#f00'}
            name="errorColor"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
        </GenericInspectRow>
        <GenericInspectRow className="default-font-control">
          <ColorDropdown
            label="Border Color"
            value={this.getFieldStyles('inputs').borderColor ?? '#ddd'}
            name="borderColor"
            onChange={this.handleChangeFieldStyles('inputs')}
          />
          <TextControl
            gray
            type="number"
            name="borderWidth"
            value={this.getFieldStyles('inputs').borderWidth ?? 1}
            onChange={this.handleChangeFieldStyles('inputs')}
            title="Border Size"
            minValue={0}
            maxValue={6}
          />
        </GenericInspectRow>
        <GenericInspectRow className="default-font-control">
          <TextControl
            gray
            type="number"
            name="padding"
            value={this.getFieldStyles('inputs').padding ?? 10}
            onChange={this.handleChangeFieldStyles('inputs')}
            title="Padding"
            minValue={0}
            maxValue={40}
          />
        </GenericInspectRow>
        <SliderControl
          name="borderRadius"
          value={this.getFieldStyles('inputs').borderRadius ?? 4}
          onChange={this.handleChangeFieldStyles('inputs')}
          title="Rounding"
          min={0}
          max={50}
          sliderBackground
        />
        <DefaultShadowControl
          name="shadow"
          object={this.getFieldStyles('inputs')}
          onChange={this.handleChangeFieldStyles('inputs')}
        />
      </div>
    )
  }

  renderSubmitButton() {
    /** @type {Props} */
    const { object, datasources, appId, branding } = this.props
    const { collection, fields, reference } = object

    if (!collection || !fields || !reference) {
      return null
    }

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    if (!datasource || !datasourceCollection) {
      return null
    }

    const table = collection.tableId
      ? datasource.tables[collection.tableId]
      : datasource.collections[collection.collectionId]

    return (
      <GroupedAccordion
        className="library-inspect-accordion"
        group={FORM_ACCORDION_GROUP}
        title="Submit Button"
        renderChildren={() => (
          <SubmitButton
            appId={appId}
            object={object}
            tableName={singularize(table.name)}
            onChange={this.handleUpdateSubmit}
            value={object.submitButton}
            branding={branding}
          />
        )}
      />
    )
  }

  renderSecondaryButton() {
    /** @type {Props} */
    const { object, appId, branding, openAccordion } = this.props
    const { collection, fields, reference } = object

    if (!collection || !fields || !reference) {
      return null
    }

    const { secondaryButton } = object
    const accordionItemId = `${object.id}-secondary-button-accordion`

    const onToggleClick = enabled => {
      this.handleUpdateSecondaryButton({ enabled })
      if (enabled) {
        openAccordion(FORM_ACCORDION_GROUP, accordionItemId)
      }
    }

    return (
      <GroupedAccordion
        itemId={accordionItemId}
        className="library-inspect-accordion"
        group={FORM_ACCORDION_GROUP}
        disabled={!secondaryButton?.enabled}
        title={
          <div className="secondary-button-inspect-title">
            <div>Secondary Button</div>
            <ToggleButton
              value={secondaryButton?.enabled}
              onChange={onToggleClick}
            />
          </div>
        }
        renderChildren={() => (
          <SecondaryButton
            appId={appId}
            object={object}
            onChange={this.handleUpdateSecondaryButton}
            onChangeParent={this.update}
            value={object.secondaryButton}
            branding={branding}
            tableId={collection.tableId}
          />
        )}
      />
    )
  }

  addNew(tableOptions) {
    const newOptions = {
      label: <NewTableOption handleChangeTable={this.handleChangeTable} />,
      value: {},
    }

    return tableOptions.concat([null, newOptions])
  }

  render() {
    const { datasources, object, tableOptions, referenceOptions, app } =
      this.props
    const { collection, reference } = object

    let options = tableOptions

    const isXanoApp = verifyXanoApp(app) // gsheet?

    if (
      datasources &&
      Object.keys(datasources)
        .map(id => datasources[id].type !== 'api')
        .includes(true) &&
      !isXanoApp
    ) {
      options = this.addNew(tableOptions)
    }

    return (
      <div>
        <GroupedAccordion
          defaultExpanded
          className="library-inspect-accordion"
          group={FORM_ACCORDION_GROUP}
          title="Form"
          renderChildren={() => (
            <React.Fragment>
              <MenuControl
                displayName="Which data collection?"
                name="collection"
                value={collection}
                onChange={this.handleChangeTable}
                options={options}
                comparator={deepEqual}
                placeholder="Select collection..."
                menuTheme={THEMES.DATA}
              />
              {collection ? (
                <MenuControl
                  displayName="What do you want the form to do?"
                  name="reference"
                  value={reference}
                  comparator={referenceComparator}
                  onChange={this.handleChangeReference}
                  options={referenceOptions}
                  menuTheme={THEMES.ACTION}
                />
              ) : null}
            </React.Fragment>
          )}
        />
        {this.renderFields()}
        {this.renderSubmitButton()}
        {this.renderSecondaryButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, { object }) => {
  const appId = getCurrentAppId(state)
  const app = getApp(state, appId)
  const component = getComponent(state, object.id)
  const { collection } = object

  const isLocationEnabled = isFeatureEnabled(state, 'geolocation')
  const isPaidOrg = getActiveState(state)
  const { trialState } = getTrialState(state)

  const branding = getAppBranding(state, appId)
  const referenceOptions = getFormReferenceOptions(
    state,
    appId,
    object.id,
    collection
  )

  return {
    app,
    appId,
    branding,
    componentId: component.id,
    tableOptions: getTableOptions(state, appId),
    datasources: getDatasourcesObject(state, appId),
    referenceOptions,
    isLocationEnabled,
    isPaidOrg,
    trialState,
  }
}

const mapDispatchToProps = dispatch => {
  const handleTrial = (trialState, isPaidOrg, appId) => {
    const handle = generateHandleTrial({
      appId,
      trialState,
      type: 'geolocation',
      dispatch,
      isPaidOrg,
    })

    handle()
  }

  return {
    handleTrial,
    updateObject: (...props) => dispatch(updateObject(...props)),
    openAccordion: (...props) => dispatch(openAccordion(...props)),
    closeAccordion: (...props) => dispatch(closeAccordion(...props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInspect)

/* eslint-disable */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dataTypes } from '@adalo/constants'
import classNames from 'classnames'

import { getUpgraded, getApp } from 'ducks/apps'
import {
  getTrialState,
  isFeatureEnabled,
  getCurrentOrganization,
} from 'ducks/organizations'
import { getAppMagicStart } from 'ducks/dbAssistant'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import EmptyState from 'components/Shared/EmptyState'
import FreeTrial from 'components/Shared/FreeTrial'
import XanoUpsellCard from 'components/Shared/UpsellCards/XanoUpsellCard'
import { GroupedAccordion } from 'components/Shared/Accordion'
import BetaLabel from 'components/AppDetail/BetaLabel'

import { verifyGsheetApp, verifyXanoApp } from 'utils/externalDatabases'

import AddCollection from './AddCollection'
import Item from './Item'

import { XANO_INTEGRATION_DOC_URL } from '../../../../../constants'

const defaultFields = [
  {
    type: dataTypes.TEXT,
    name: 'Name',
    isPrimaryField: true,
  },
]

const Items = props => {
  const {
    appId,
    createTable,
    datasources,
    datasourceId,
    metrics,
    removeTable,
    updateTable,
  } = props

  const datasource = datasources.length === 1 && datasources[0]

  const history = useHistory()

  const [createTableId, setCreateTableId] = useState('')
  const [isAddingCollection, setIsAddingCollection] = useState(false)
  const [showAddGsheetCollection, setShowAddGsheetCollection] = useState(false)
  const [search, setSearch] = useState('')

  const handleSearch = event => setSearch(event.target.value)

  const toggleAddCollection = () => {
    setIsAddingCollection(!isAddingCollection)
  }

  const { isAfterTrial, planType } = useSelector(state => {
    const { trialState } = getTrialState(state)

    const paying = getUpgraded(state, appId)
    const isAfterTrial = !paying && trialState === 'after'

    const currentOrg = getCurrentOrganization(state)

    return { isAfterTrial, planType: currentOrg?.planType }
  })

  const isEnabled = useSelector(state =>
    isFeatureEnabled(state, 'customIntegrations')
  )

  const isExternalDatabaseEnabled = useSelector(state =>
    isFeatureEnabled(state, 'externalDatabase')
  )

  const appMagicStart = useSelector(state => getAppMagicStart(state, appId))

  const app = useSelector(state => getApp(state, appId))

  if (!datasource) return null
  if (!datasource.tables) return null

  const isXanoApp = verifyXanoApp(app)
  const isGsheetApp = verifyGsheetApp(app)

  // convert tables object into an array of tables
  const tables = Object.keys(datasource.tables).map(i => {
    // convert table key to tabledId property in object
    datasource.tables[i].tableId = i

    return datasource.tables[i]
  })

  const xanoTables = [
    tables.find(i => i.type === 'xano' && i.name === 'Users'),
    ...tables.filter(i => i.name !== 'Users' && i.type === 'xano'),
  ]

  const gsheetTables = [
    tables.find(i => i.type === 'gsheet' && i.name === 'Users'),
    ...tables.filter(i => i.name !== 'Users' && i.type === 'gsheet'),
  ]

  const externalCollectionTypes = ['xano', 'api', 'gsheet']

  // sort the tables where 'Users' is first, with everything else following
  const normalTables = [
    tables.find(
      i => i.name === 'Users' && !externalCollectionTypes.includes(i.type)
    ),
    ...tables
      .filter(
        i => i.name !== 'Users' && !externalCollectionTypes.includes(i.type)
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
  ].filter(i =>
    search ? i.name.toLowerCase().includes(search.toLowerCase()) : true
  )

  const apiTables = tables.filter(i => i.type === 'api')

  const databaseTables = isGsheetApp ? gsheetTables : normalTables
  const dataSource = isGsheetApp ? 'Google Sheet' : 'Database'

  const handleAddCollection = values => {
    let table
    if (isGsheetApp) {
      table = createTable(appId, datasourceId, {
        tableType: 'gsheet',
        spreadsheetId: datasource.spreadsheetId,
        name: String(values.name).trim(),
        id: null,
        fields: defaultFields,
      })
    } else {
      table = createTable(
        appId,
        datasourceId,
        String(values.name).trim(),
        null,
        defaultFields
      )
    }
    const { id } = table

    return setCreateTableId(id)
  }

  const renderAddCollection = () => {
    const options = [
      {
        label: 'Add Collection',
        onClick: toggleAddCollection,
        icon: 'add-collection',
      },
      {
        label: 'Magic Add',
        onClick: () => {
          return history.push(`/apps/${appId}/data/magic-add`)
        },
        icon: 'ai-star',
        magicAdd: true,
      },
    ]

    if (!isAddingCollection) {
      const appSupportsMagicStart =
        !isXanoApp && app?.primaryPlatform === 'responsive' // and now gsheet?

      const magicStartIsAvailable =
        appMagicStart?.limits?.schemaGenerateAvailable === true

      const magicStartIsEnabled = appSupportsMagicStart && magicStartIsAvailable

      const magicAddIsEnabled =
        !magicStartIsEnabled && app?.primaryPlatform === 'responsive'

      // Cant' show MagicAdd if MagicStart is enabled.
      // If no collections were created, the User should go for the MagicStart feature
      if (magicAddIsEnabled) {
        return (
          <GroupedAccordion
            className="add-to-database__accordion"
            title={
              <Button component="div" orange listAddButton>
                <Icon type="plus" /> Add To Database
              </Button>
            }
            hideOnly
            hideCarret
            renderChildren={() => (
              <div className="add-to-database__accordion__children">
                {options.map(option => (
                  <div
                    className={classNames({
                      'magic-add': option.magicAdd,
                    })}
                    key={option.label}
                    onClick={option.onClick}
                  >
                    {option.magicAdd ? <BetaLabel /> : null}
                    <Icon big color="darkerGray" type={option.icon} />
                    <span>{option.label}</span>
                  </div>
                ))}
              </div>
            )}
          />
        )
      }

      return (
        <div className="add-collection-wrapper">
          <Button
            component="div"
            orange
            onClick={toggleAddCollection}
            listAddButton
          >
            <Icon type="plus" /> Add Collection
          </Button>
        </div>
      )
    }

    const collectionProps = {
      createTableId,
      onSubmit: handleAddCollection,
      isAddingCollection,
      toggle: toggleAddCollection,
    }

    return <AddCollection {...collectionProps} />
  }

  const addApiUrl = `/apps/${appId}/data/new-external-collection`
  const addXanoCollectionUrl = `/apps/${appId}/external-database/setup?step=2` // gsheet?

  const isEmpty = apiTables.length === 0

  const showFeature = isEnabled || !isEmpty

  const showButton = isEnabled

  // Define the upsell card to be displayed based on account status.
  let upsellCard = <FreeTrial appId={appId} type="externalCollection" />

  const showXanoExtCollectionUpsell =
    [
      'pro',
      'professional',
      'professional2023',
      'professional2024',
      'team',
      'team2024',
      'business',
      'business2022',
      'business2023',
      'business2024',
    ].includes(planType) && !isEmpty

  if (showXanoExtCollectionUpsell) {
    // gsheet?
    upsellCard = <XanoUpsellCard />
  }

  const renderExternalCollections = () => {
    return (
      <div>
        <p className="data-collections-section-title">External Collections</p>
        {showFeature && (
          <>
            {apiTables.map(table => {
              if (!table) return null

              return (
                <Item
                  key={table.tableId}
                  appId={appId}
                  datasourceId={datasourceId}
                  removeTable={removeTable}
                  updateTable={updateTable}
                  {...table}
                />
              )
            })}
            {isEmpty && !isAfterTrial && (
              <EmptyState bordered className="data-api-collection-empty-state">
                <h2>Connect to APIs</h2>
                <p>
                  Make your app even more powerful
                  <br />
                  by connecting to 3rd party APIs
                  <a
                    className="upsell-link"
                    href={XANO_INTEGRATION_DOC_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    LEARN MORE
                  </a>
                </p>
              </EmptyState>
            )}
            {showButton && (
              <div className="add-collection-wrapper">
                <Button
                  orange
                  listAddButton
                  to={addApiUrl}
                  className="add-collection-button"
                >
                  <Icon type="plus" />
                  {' Add Collection'}
                </Button>
              </div>
            )}
          </>
        )}
        {upsellCard}
      </div>
    )
  }

  if (isXanoApp) {
    return (
      <>
        <div>
          <p className="data-collections-section-title">Xano Collections</p>
          {xanoTables.map(table => {
            if (!table) return null
            const metric = metrics[table.tableId]

            return (
              <Item
                key={table.tableId}
                appId={appId}
                datasourceId={datasourceId}
                metric={metric}
                removeTable={removeTable}
                updateTable={updateTable}
                {...table}
              />
            )
          })}
          {isExternalDatabaseEnabled && isXanoApp ? (
            <div className="add-collection-wrapper">
              <Button
                orange
                listAddButton
                to={addXanoCollectionUrl}
                className="add-collection-button"
              >
                <Icon type="plus" /> Import Xano Collection
              </Button>
            </div>
          ) : null}
          <FreeTrial appId={appId} type="externalDatabase" />
        </div>
        {renderExternalCollections()}
      </>
    )
  } else {
    return (
      <>
        <div>
          <div className="editor-add-panel-search">
            <i className="material-icons">search</i>
            <input
              placeholder="Search collections..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          <p className="data-collections-section-title">
            {dataSource} Collections
          </p>
          {databaseTables.map(table => {
            if (!table) return null
            const metric = metrics[table.tableId]

            return (
              <Item
                key={table.tableId}
                appId={appId}
                datasourceId={datasourceId}
                metric={metric}
                removeTable={removeTable}
                updateTable={updateTable}
                {...table}
              />
            )
          })}
          {databaseTables.length === 0 && (
            <EmptyState bordered className="data-collection-empty-state">
              No collections found
            </EmptyState>
          )}

          {renderAddCollection()}
        </div>
        {renderExternalCollections()}
      </>
    )
  }
}

export default Items

import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createApp } from 'utils/io'
import { defaultBranding } from 'utils/colors'
import { verifyXanoApp } from 'utils/externalDatabases'
import { getNewAppWebSettings } from 'utils/webSettings'

import { getApp } from 'ducks/apps'

import Modal from 'components/Shared/Modal'

import CopyAppForm from './Form'

class CopyApp extends Component {
  handleSubmit = async formValues => {
    const { app } = this.props

    if (!app) {
      return
    }

    const {
      id: appId,
      branding = {},
      webSettings: sourceWebSettings,
      OrganizationId,
    } = app

    let { copyAsResponsive, appType, ...values } = formValues
    let mobileOnly = false

    if (appType) {
      copyAsResponsive = appType !== 'legacy'
      mobileOnly = appType === 'mobile'
    }

    const magicLayout = app?.magicLayout || copyAsResponsive

    const primaryPlatform = copyAsResponsive
      ? 'responsive'
      : app.primaryPlatform

    const webSettings = getNewAppWebSettings({
      sourceWebSettings,
      primaryPlatform,
      mobileOnly,
    })

    let opts = {
      ...values,
      branding: {
        ...defaultBranding,
        ...branding,
      },
      primaryPlatform,
      prototypeAppId: appId,
      magicLayout,
      webSettings,
    }

    if (values.copyDatabase === '0') {
      opts = {
        ...values,
        branding: {
          ...defaultBranding,
          ...branding,
        },
        primaryPlatform,
        datasourceAppId: appId,
        copyApp: true,
        copyDatabase: undefined,
        datasourceType: 'copy',
        magicLayout,
        webSettings,
        organizationId: OrganizationId,
      }
    }

    createApp(opts)

    return this.handleCancel()
  }

  handleCancel = () => {
    const { match, history } = this.props
    const { appId } = match.params

    history.push(`/apps/${appId}/screens`)
  }

  render() {
    const { app } = this.props
    const isXanoApp = verifyXanoApp(app) // gsheet
    const isResponsiveApp = app?.magicLayout
    const primaryPlatform = app?.primaryPlatform

    if (!app) {
      return null
    }

    const initialValues = {
      name: `${app.name} Copy`,
      copyAsResponsive: false,
      organizationId: app.OrganizationId,
      copyDatabase: '1',
    }

    if (primaryPlatform !== 'responsive') {
      initialValues.appType = 'legacy'
    }

    return (
      <Modal>
        <CopyAppForm
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          initialValues={initialValues}
          isXanoApp={isXanoApp} // add copy for xano
          isResponsiveApp={isResponsiveApp}
          primaryPlatform={primaryPlatform}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
})

export default connect(mapStateToProps)(CopyApp)

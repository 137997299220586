import { v4 as uuid } from 'uuid'

type UserData = {
  table: string
  endpoints: {
    [key: string]: string[]
  }
  missingEndpoints?: {
    [key: string]: string[]
  }
}

type UserCollectionData = {
  table: string
  fields: UserField
  endpoints: {
    [key: string]: string[]
  }
}

type UserEndpoint = {
  entity: string
  path: string
  label: string
  type: string
  isValid: boolean
}

type ValidateUserEndpointsResponse = {
  endpointsAreValid: boolean
  userEndpoints: UserEndpoint[]
  capitalizedSchema: string
}

export type AppExternalUsers = {
  externalUsers: {
    enabled: boolean
    provider?: string
    login?: {
      customActionId: string
      formValues?: CustomAction
      authToken: string
    }
    signup?: {
      customActionId: string
      formValues?: CustomAction
      authToken: string
    }
  }
}

type CustomActionResponse = {
  value: {
    id: string
  }
  authTokenField: string
  idField: string
}

type CustomActionInput = {
  type: string
  label: string
  value: {
    id: string
  }
  exampleText?: string
}

type CustomActionObject = {
  id: string
  label: string
}

type CustomAction = {
  id: string
  body: Array<CustomActionObject | string>
  name: string
  type: string
  appId: string
  inputs: CustomActionInput[]
  method: string
  baseURL: string
  outputs: {
    authToken: {
      key: string
      name: string
      type: string
      sampleValue: string
    }
  }
  authTokenField: {
    key: string
    name: string
    type: string
    sampleValue: string
    formattedKey: string
  }
}

type CustomActionFunction = (
  appId: string,
  loginCustomAction: CustomAction
) => Promise<CustomActionResponse>

type SaveCustomActionResponse = {
  customActionId: string
  customAction: CustomAction
}

type SignupData = {
  requestBody: Record<string, string>
  responseBody: Record<string, string>
}

type RequestMetadata = {
  body: CustomAction['body']
  input: CustomAction['inputs']
}

type DatasourceAuth = {
  name: string
  type: string
  value?: string
}

type DatasourceEndpoint = {
  [key: string]: {
    url: string
    method: string
    enabled: boolean
  }
}

export type DatasourceTables = {
  [key: string]: DatasourceTable
}

export type DatasourceTable = {
  id: string
  auth: DatasourceAuth[] | DatasourceAuth
  baseURL: string
  endpoints: DatasourceEndpoint
  type: string
  fields: TableFields
  name: string
  orderedFields?: string[]
  tableId: string
}

export type TableField = {
  name: string
  type:
    | string
    | {
        type: string
        tableId: string
        datasourceId: string
      }
  locked?: boolean
  isPrimaryField?: boolean
}

export type TableFields = {
  [key: string]: TableField
}

export type Datasource = {
  auth: {
    table: string
  }
  id: string
  tables: DatasourceTables
}

export type UserField = {
  [key: string]: {
    type: string
    required: boolean
    locked: boolean
    name: string
  }
}

type LoginData = {
  requestBody: Record<string, string>
  responseBody: Record<string, string>
}

type UpdateUserDatasourcesResponse = {
  datasourceId: string
  tableId: string
  updatedUserTable: DatasourceTable
}

export const validateUserEndpoints = (
  userData: UserData
): ValidateUserEndpointsResponse => {
  const schema = userData.table

  const capitalizedSchema = `${schema[0]?.toUpperCase() ?? ''}${schema.slice(
    1
  )}`

  const USER_ENDPOINTS = {
    [capitalizedSchema]: [] as UserEndpoint[],
  }

  const rootPath = `/${schema.toLowerCase()}`

  const userPaths = userData.endpoints[`/${userData.table}`]
  const userIdPaths = userData.endpoints[`/${userData.table}/{id}`]

  userPaths?.forEach(path => {
    switch (path) {
      case 'get':
        USER_ENDPOINTS[capitalizedSchema]?.push({
          entity: schema,
          label: 'Get All',
          type: 'get',
          path: rootPath,
          isValid: true,
        })

        break

      case 'post':
        USER_ENDPOINTS[capitalizedSchema]?.push({
          entity: schema,
          label: 'Create',
          type: 'post',
          path: rootPath,
          isValid: true,
        })

        break

      default:
        break
    }
  })

  userIdPaths?.forEach(path => {
    switch (path) {
      case 'get':
        USER_ENDPOINTS[capitalizedSchema]?.push({
          entity: schema,
          label: 'Get One',
          type: 'get',
          path: `${rootPath}/{id}`,
          isValid: true,
        })

        break

      case 'post':
      case 'patch':
      case 'put':
        USER_ENDPOINTS[capitalizedSchema]?.push({
          entity: schema,
          label: 'Update',
          type: path,
          path: `${rootPath}/{id}`,
          isValid: true,
        })

        break

      case 'delete':
        USER_ENDPOINTS[capitalizedSchema]?.push({
          entity: schema,
          label: 'Delete',
          type: 'delete',
          path: `${rootPath}/{id}`,
          isValid: true,
        })

        break

      default:
        break
    }
  })

  let endpointsAreValid = true

  if (userData.missingEndpoints) {
    endpointsAreValid = false

    const userMissingPaths = userData.missingEndpoints[`/${userData.table}`]
    const userIdMissingPaths =
      userData.missingEndpoints[`/${userData.table}/{id}`]

    userMissingPaths?.forEach(path => {
      switch (path) {
        case 'get':
          USER_ENDPOINTS[capitalizedSchema]?.push({
            entity: schema,
            label: 'Get All',
            type: 'get',
            path: rootPath,
            isValid: false,
          })

          break

        case 'post':
          USER_ENDPOINTS[capitalizedSchema]?.push({
            entity: schema,
            label: 'Create',
            type: 'post',
            path: rootPath,
            isValid: false,
          })

          break

        default:
          break
      }
    })

    userIdMissingPaths?.forEach(path => {
      switch (path) {
        case 'get':
          USER_ENDPOINTS[capitalizedSchema]?.push({
            entity: schema,
            label: 'Get One',
            type: 'get',
            path: `${rootPath}/{id}`,
            isValid: false,
          })

          break

        case 'update_not_found':
          USER_ENDPOINTS[capitalizedSchema]?.push({
            entity: schema,
            label: 'Update',
            type: 'not found',
            path: `${rootPath}/{id}`,
            isValid: false,
          })

          break

        case 'delete':
          USER_ENDPOINTS[capitalizedSchema]?.push({
            entity: schema,
            label: 'Delete',
            type: 'delete',
            path: `${rootPath}/{id}`,
            isValid: false,
          })

          break

        default:
          break
      }
    })
  }

  const userEndpoints = USER_ENDPOINTS[capitalizedSchema] as UserEndpoint[]

  const updateEndpointTypes = ['patch', 'put', 'post']

  const updateUserEndpoints = userEndpoints.filter(
    endpoint =>
      updateEndpointTypes.includes(endpoint.type) &&
      endpoint.path === `/${userData.table}/{id}`
  )

  const filteredUserEndpoints = userEndpoints.filter(endpoint => {
    const userEndpoint = endpoint.path === `/${userData.table}`

    const otherUserByIdEndpoint =
      !updateEndpointTypes.includes(endpoint.type) &&
      endpoint.path === `/${userData.table}/{id}`

    return userEndpoint || otherUserByIdEndpoint
  })

  // When multiple UPDATE endpoints are found (PUT/PATCH/POST), we'll only grab the last one
  const mergedUserEndpoints = filteredUserEndpoints.concat(
    updateUserEndpoints.slice(-1)
  )

  return {
    endpointsAreValid,
    userEndpoints: mergedUserEndpoints,
    capitalizedSchema,
  }
}

const findExistingBodyItem = (
  externalUsers: AppExternalUsers['externalUsers'],
  authMethod: 'login' | 'signup',
  label: string
): CustomActionObject | string | undefined =>
  externalUsers[authMethod]?.formValues?.body.find(prop => {
    if (typeof prop === 'object' && prop.label === label) {
      return true
    }

    return false
  })

// TODO: move to backend
export const saveLoginCustomAction = async (
  appId: string,
  app: AppExternalUsers,
  loginData: LoginData,
  createCustomAction: CustomActionFunction,
  baseUrl: string
): Promise<SaveCustomActionResponse> => {
  const requestMetadata = {
    body: [],
    input: [],
  } as RequestMetadata

  let index = 0

  for (const [key, value] of Object.entries(loginData.requestBody)) {
    const propUuid = uuid()
    const capitalizedKey = `${key[0]?.toUpperCase() ?? ''}${key.slice(1)}`

    if (index === 0) {
      // eslint-disable-next-line no-useless-escape
      requestMetadata.body.push(`{\n\"${key}\": \"`)
    } else {
      // eslint-disable-next-line no-useless-escape
      requestMetadata.body.push(`\",\n\"${key}\": \"`)
    }

    const propBody = findExistingBodyItem(
      app.externalUsers,
      'login',
      capitalizedKey
    )

    requestMetadata.body.push({
      id: typeof propBody === 'object' ? propBody.id : propUuid,
      label: capitalizedKey,
    })

    requestMetadata.input.push({
      type: value,
      label: capitalizedKey,
      value: {
        id: typeof propBody === 'object' ? propBody.id : propUuid,
      },
    })

    index += 1
  }

  requestMetadata.body.push('"\n}')

  // huh?
  // TODO: Move to backend. The frontend shouldn’t need to know how backend should interact with the API… that’s the backend’s responsibility.
  const loginCustomAction = {
    id: uuid(),
    body: requestMetadata.body,
    name: 'Xano Login',
    type: 'External Users',
    appId,
    inputs: requestMetadata.input,
    method: 'post',
    baseURL: `${baseUrl}/auth/login`,
    outputs: {
      authToken: {
        key: 'authToken',
        name: 'authToken',
        type: 'text',
        sampleValue: '',
      },
    },
    authTokenField: {
      key: 'authToken',
      name: 'authToken',
      type: 'text',
      sampleValue: '',
      formattedKey: 'authToken',
    },
  }

  let customActionId
  let customAction

  try {
    if (app.externalUsers.login?.customActionId) {
      loginCustomAction.id = app.externalUsers.login.customActionId
    }

    customAction = await createCustomAction(appId, loginCustomAction)

    if (!customAction) {
      throw new Error(`[XANO] - Failed to create custom action: ${appId}`)
    }

    customActionId = customAction.value?.id
  } catch (err) {
    console.error('ERROR CREATING LOGIN ACTION:', err)
  }

  if (!customAction || !customActionId) {
    throw new Error(
      `[XANO] - Failed to fetch custom action: ${JSON.stringify(
        customActionId ?? {}
      )}`
    )
  }

  return {
    customActionId,
    customAction: loginCustomAction,
  }
}

export const saveSignupCustomAction = async (
  appId: string,
  app: AppExternalUsers,
  signupData: SignupData,
  createCustomAction: CustomActionFunction,
  baseUrl: string
): Promise<SaveCustomActionResponse> => {
  const requestMetadata = {
    body: [],
    input: [],
  } as RequestMetadata

  let index = 0

  for (const [key, value] of Object.entries(signupData.requestBody)) {
    const propUuid = uuid()
    const capitalizedKey = `${key[0]?.toUpperCase() ?? ''}${key.slice(1)}`

    if (index === 0) {
      // eslint-disable-next-line no-useless-escape
      requestMetadata.body.push(`{\n\"${key}\": \"`)
    } else {
      // eslint-disable-next-line no-useless-escape
      requestMetadata.body.push(`\",\n\"${key}\": \"`)
    }

    const propBody = findExistingBodyItem(
      app.externalUsers,
      'signup',
      capitalizedKey
    )

    requestMetadata.body.push({
      id: typeof propBody === 'object' ? propBody.id : propUuid,
      label: capitalizedKey,
    })

    requestMetadata.input.push({
      type: value,
      label: capitalizedKey,
      value: {
        id: typeof propBody === 'object' ? propBody.id : propUuid,
      },
    })

    index += 1
  }

  requestMetadata.body.push('"\n}')

  // huh?
  const signupCustomAction = {
    id: uuid(),
    body: requestMetadata.body,
    name: 'Xano Signup',
    type: 'External Users',
    appId,
    inputs: requestMetadata.input,
    method: 'post',
    baseURL: `${baseUrl}/auth/signup`,
    outputs: {
      authToken: {
        key: 'authToken',
        name: 'authToken',
        type: 'text',
        sampleValue: '',
      },
    },
    authTokenField: {
      key: 'authToken',
      name: 'authToken',
      type: 'text',
      sampleValue: '',
      formattedKey: 'authToken',
    },
  }

  let customActionId
  let customAction

  try {
    if (app.externalUsers.signup?.customActionId) {
      signupCustomAction.id = app.externalUsers.signup?.customActionId ?? ''
    }

    customAction = await createCustomAction(appId, signupCustomAction)

    if (!customAction) {
      throw new Error(`[XANO] - Failed to create custom action: ${appId}`)
    }

    customActionId = customAction.value?.id
  } catch (err) {
    console.error('ERROR CREATING LOGIN ACTION:', err)
  }

  if (!customAction || !customActionId) {
    throw new Error(
      `[XANO] - Failed to fetch custom action: ${JSON.stringify(
        customAction ?? {}
      )}`
    )
  }

  return {
    customActionId,
    customAction: signupCustomAction,
  }
}

export const updateUsersDatasources = (
  datasource: Datasource,
  userData: UserCollectionData,
  url: string
): UpdateUserDatasourcesResponse => {
  const userTableId = Object.keys(datasource.tables).find(
    tableId => datasource.auth.table === tableId
  )

  if (!userTableId) {
    throw new Error(
      '[XANO] - Could not find auth table id in datasource tables'
    )
  }

  const userTable = datasource.tables[userTableId]

  if (!userTable) {
    throw new Error('[XANO] - Could not find user table')
  }

  const mergedUserTable = userTable

  // resets fields to avoid duplication or lingering properties
  mergedUserTable.orderedFields = []
  mergedUserTable.fields = {}

  if (!mergedUserTable) {
    throw new Error('[XANO] - Could not find user datasource table')
  }

  const tableFields = userTable.fields

  if (!tableFields) {
    throw new Error('[XANO] - Could not find auth datasource fields')
  }

  mergedUserTable.auth = [
    {
      name: 'Authorization',
      type: 'header',
      value: '',
    },
  ]

  for (const [key, value] of Object.entries(userData.fields)) {
    mergedUserTable.fields[key] = {
      name: value.name,
      type: value.type,
      locked: key === 'id',
      isPrimaryField: key === 'id',
    }

    if (!mergedUserTable.orderedFields?.includes(key)) {
      mergedUserTable.orderedFields.push(key)
    }

    mergedUserTable.baseURL = url
  }

  // test for endpoints
  userData.endpoints[`/${userData.table}/{id}`]?.forEach(endpoint => {
    switch (endpoint) {
      case 'put':
      case 'patch':
      case 'post':
        mergedUserTable.endpoints['update'] = {
          url: `${url}/${userData.table}/{{id}}`,
          method: endpoint,
          enabled: true,
        }

        break

      case 'delete':
        mergedUserTable.endpoints['delete'] = {
          url: `${url}/${userData.table}/{{id}}`,
          method: 'delete',
          enabled: true,
        }

        break

      case 'get':
        mergedUserTable.endpoints['detail'] = {
          url: `${url}/${userData.table}/{{id}}`,
          method: 'get',
          enabled: true,
        }

        break

      default:
        break
    }
  })

  userData.endpoints[`/${userData.table}`]?.forEach(endpoint => {
    switch (endpoint) {
      case 'post':
        mergedUserTable.endpoints['create'] = {
          url: `${url}/${userData.table}`,
          method: 'post',
          enabled: true,
        }

        break

      case 'get':
        mergedUserTable.endpoints['list'] = {
          url: `${url}/${userData.table}`,
          method: 'get',
          enabled: true,
        }

        break

      default:
    }
  })

  return {
    datasourceId: datasource.id,
    tableId: userTableId,
    updatedUserTable: mergedUserTable,
  }
}

import {
  FORM,
  actionTypes,
  bindingTypes,
  sourceTypes,
  sourceTypeNames,
  selectors,
  dataTypes,
  sortDirections,
  fields as protonFields,
  LOCATION_INPUT,
  FILE_UPLOAD,
  IMAGE_UPLOAD,
} from '@adalo/constants'

import {
  pathLength,
  subPath,
  getObject,
  uniqueElements,
  sort,
} from '@adalo/utils'

import update from 'immutability-helper'

import { memoize } from 'utils/memoization'
import { verifyGsheetApp, verifyXanoApp } from 'utils/externalDatabases'
import { isEmpty as _isEmpty } from 'lodash'

import {
  allowedBindingTypes,
  bestBindingType,
  getDataTypes,
} from 'utils/objects'

import { getTableField } from 'utils/tables'
import { AUTHENTICATED, CURRENT } from 'utils/terms'
import { getSortLabel } from 'utils/sorting'
import { getFields, getFieldIds } from 'utils/fields'
import { dateTimeOffsets } from 'utils/datetime'
import { getObjectName } from 'utils/naming'
import {
  singularize,
  pluralize,
  possessiveExpansion,
  stripPossessive,
} from 'utils/strings'
import { capitalize } from 'utils/type'
import { getContextualTables } from 'utils/links'
import {
  getLibraryPropLabel,
  getAppLibrary,
  getComponentInfo,
} from 'utils/libraries'
import {
  getTableInfo,
  buildTableSource,
  buildRouteParamSource,
  buildFieldSource,
  buildURLFieldSource,
  buildCountSource,
  buildListItemSource,
  buildSourceObject,
  buildBelongsToSource,
  buildHasManySource,
  buildManyToManySource,
  buildSelectSource,
  buildInputSource,
  buildLibraryInputSource,
  buildDateTimeSource,
  buildParamSource,
  buildCreatedObjectSource,
  buildAggregateSource,
  buildAutosaveSource,
  buildAPIEndpointSource,
  buildAPIFieldSource,
  buildCustomActionSource,
  aggregateSourceTypes,
  limitSourceTypes,
  buildActionArgumentSource,
  buildDeviceLocationSource,
} from 'utils/sources'
import { buildBinding } from 'utils/bindings'

import {
  getHasManyRelations,
  getBelongsToRelations,
  getManyToManyRelations,
} from 'utils/relations'

import {
  usesCollections,
  hasTimestamps,
  getCollection,
} from 'utils/datasources'

import {
  getEndpointLabel,
  getEndpointSubtitle,
  getDataEndpoints,
  isListEndpoint,
} from 'utils/apis'

import { getApp, getInboundLinks, getScreens } from 'ducks/apps'

import {
  getDatasources,
  getDatasourcesObject,
  getDatasource,
  getTable,
  getOrderedFields,
} from 'ducks/apps/datasources'

import { getParams } from 'ducks/apps/params'
import {
  getCurrentAppId,
  selectObject,
  selectObjects,
  getInputs,
  getCheckboxes,
  getImagePickers,
  getFilePickers,
  getDatePickers,
  getSelects,
  getPath,
  getComponent,
  getLocationInputs,
} from 'ducks/editor/objects'

import { getCustomAction } from 'ducks/customActions'

import { getThirdPartyApiKeyFor } from 'ducks/thirdPartyApiKeys'

import { getFieldIcon } from 'utils/icons'

import filterByClickableItems from 'utils/filter-flyouts'

import { GoogleTooltip } from 'components/Shared/GoogleHelpLink'
import Icon from 'components/Shared/Icon'
import { isFeatureEnabled } from 'ducks/organizations'

const MORE_MENU_LABEL = 'More...'
const OTHER_COMPONENTS_MENU_LABEL = 'Other Components'

const LOCATION_OPTIONS = {
  [dataTypes.TEXT]: [
    { label: 'Full Address', fieldId: 'fullAddress' },
    { label: 'Name', fieldId: 'name' },
    { label: 'Street Address', fieldId: 'addressElements.address1' },
    { label: 'City', fieldId: 'addressElements.city' },
    { label: 'State/Region', fieldId: 'addressElements.region' },
    { label: 'Country', fieldId: 'addressElements.country' },
    { label: 'Postal Code', fieldId: 'addressElements.postalCode' },
  ],
  [dataTypes.NUMBER]: [
    { label: 'Latitude', fieldId: 'coordinates.latitude' },
    { label: 'Longitude', fieldId: 'coordinates.longitude' },
  ],
}

const FILE_OPTIONS = {
  [dataTypes.TEXT]: [
    { label: 'Filename', fieldId: 'filename' },
    { label: 'URL', fieldId: 'url' },
  ],
  [dataTypes.NUMBER]: [{ label: 'Size', fieldId: 'size' }],
}

const IMAGE_OPTIONS = {
  [dataTypes.TEXT]: [{ label: 'URL', fieldId: 'url' }],
}

const INPUT_OPTION_MAP = {
  [LOCATION_INPUT]: LOCATION_OPTIONS,
  [FILE_UPLOAD]: FILE_OPTIONS,
  [IMAGE_UPLOAD]: IMAGE_OPTIONS,
}

export class Submenu {
  /**
   * @param {String} label
   * @param {Array} children
   */
  constructor(label, children, icon) {
    this.label = label
    this.children = children
    this.icon = icon
  }
}

export class MenuOption {
  /**
   * @param {String} label
   * @param {Object} value
   * @param {String} icon
   */
  constructor(label, value, icon) {
    this.label = label
    this.value = value
    this.icon = icon
  }
}

// ACTIONS-SPECIFIC FIELD OPTIONS

// Returns options for Actions panel fields section
export const getFieldOptions = (
  state,
  appId,
  componentId,
  objectId,
  datasourceId,
  tableId,
  fieldId,
  actionId,
  reference,
  helpers
) => {
  const datasources = getDatasourcesObject(state, appId)
  const datasource = datasources[datasourceId]
  const table = datasource && datasource.tables && datasource.tables[tableId]

  let field = table && table.fields[fieldId]

  field = { ...field }

  // First, make sure we're on the right app
  // This is just a check to prevent overwriting data accidentally
  // Should something go wrong, better safe than sorry!
  const activeAppId = getCurrentAppId(state)
  if (activeAppId !== appId) return []

  if (!field) return []

  const result = []

  let includeLiterals = false

  if (field && field.type === dataTypes.BOOLEAN) {
    includeLiterals = true
  }

  let { type } = field || {}

  if (!type) return []

  if (type.type === 'belongsTo') {
    type = { ...type, type: dataTypes.OBJECT }
  }

  return () => {
    let sources

    if (type.type && type.type.includes('manyToMany')) {
      const objectType = { ...type, type: dataTypes.OBJECT }

      sources = getSources(
        state,
        appId,
        componentId,
        objectId,
        [objectType],
        false,
        actionId,
        reference,
        includeLiterals,
        undefined,
        undefined,
        undefined,
        helpers
      )()

      sources = evaluateOptions(sources, 6)
      sources = flattenOptions(sources, null, true)

      const addOperation = operation => {
        const value = sources.map(source => {
          if (source) {
            source = update(source, {
              value: {
                options: options =>
                  update(options || {}, {
                    operation: { $set: operation },
                  }),
              },
            })

            return source
          }
        })

        return value
      }

      const options = [
        new Submenu('Add', addOperation(actionTypes.CREATE_ASSOCIATION)),
        new Submenu('Remove', addOperation(actionTypes.DELETE_ASSOCIATION)),
        null,
        new MenuOption('No Change', ''),
      ]

      return result.concat(options)
    }

    sources = getSources(
      state,
      appId,
      componentId,
      objectId,
      [type],
      false,
      actionId,
      reference,
      includeLiterals,
      filterByClickableItems,
      undefined,
      undefined,
      helpers
    )()

    if (sources.length > 0 && result.length > 0) {
      result.push(null)
    }

    return result.concat(sources)
  }
}

// BEGINNING OF DATA BINDING SUGGESTIONS SECTION

export const getBindingSuggestions = (
  state,
  appId,
  componentId,
  objectId,
  sourceOnly,
  bindingType,
  actionId = null,
  reference = null,
  helpers = {}
) => {
  const object = selectObject(state, objectId)

  const objectBindingTypes = bindingType
    ? [bindingType]
    : allowedBindingTypes[object.type]

  const objectDataTypes = getDataTypes(objectBindingTypes)

  const sources = getSources(
    state,
    appId,
    componentId,
    objectId,
    objectDataTypes,
    false,
    actionId,
    reference,
    undefined,
    undefined,
    undefined,
    undefined,
    helpers
  )

  if (sourceOnly) {
    return sources
  }

  return addBindings(sources, objectBindingTypes)
}

// Get binding suggestions for autosave inputs

export const getAutosaveInputOptions =
  (state, appId, componentId, objectId, dataType, reference, actionId) =>
  () => {
    const options = getSources(
      state,
      appId,
      componentId,
      objectId,
      [dataTypes.OBJECT],
      null,
      actionId,
      reference
    )

    let objectSources = flattenOptions(options())

    objectSources = sort(
      objectSources,
      source => source.label.split('').filter(l => l === '>').length
    )

    const results = []

    const getNewChildren = memoize((allChild, relationTableId) => {
      return () => {
        const options = getSources(
          state,
          appId,
          componentId,
          objectId,
          [{ type: 'belongsTo', tableId: relationTableId }],
          null,
          actionId,
          reference
        )

        const objectSources = flattenOptions(evaluateOptions(options, 2))

        return objectSources.map(
          itm =>
            new MenuOption(
              `Includes ${itm.label}?`,
              buildAutosaveSource(allChild.value, itm.value),
              itm.icon || 'check'
            )
        )
      }
    })

    const getChildren = memoize(
      (table, optValue, datasource, allowedDataTypes) => {
        const results = getTableFieldOptions(
          state,
          table,
          optValue,
          datasource,
          allowedDataTypes
        ).map(child => {
          if (!child || child.label.match(/\)$/)) {
            return null
          }

          if (child.value?.fieldId === 'id') {
            return null
          }

          if (child.value) {
            return { ...child, children: undefined }
          }

          // Now it's a list
          let { children } = child

          children = evaluateOptions(children, 2)

          const allChild = children.filter(
            itm =>
              itm && itm.value && itm.value.type === sourceTypes.MANY_TO_MANY
          )[0]

          if (!allChild) {
            return null
          }

          const { tableId: relationTableId } = allChild.value

          const newChildren = getNewChildren(allChild, relationTableId)

          return {
            ...child,
            children: newChildren,
          }
        })

        return results.filter(itm => itm)
      },
      (table, optValue, datasource, allowedDataTypes) => {
        return `${optValue.tableId},${JSON.stringify(allowedDataTypes)}`
      }
    )

    objectSources.forEach(opt => {
      const item = { label: opt.label, children: [] } //, inline: true }
      const { tableId, datasourceId } = opt.value
      const table = getTable(state, appId, datasourceId, tableId)
      const datasource = getDatasource(state, appId, datasourceId)

      const allowedDataTypes =
        dataType === dataTypes.BOOLEAN ? [dataType, dataTypes.LIST] : [dataType]

      const children = getChildren(
        table,
        opt.value,
        datasource,
        allowedDataTypes
      )

      const processedChildren = []

      for (const child of children) {
        if (!child) {
          processedChildren.push(child)

          continue
        }

        if (child.value) {
          processedChildren.push(
            new MenuOption(
              child.label,
              {
                ...child.value,
                source: {
                  ...child.value.source,
                  source: opt.value,
                },
              },
              child.icon || 'check'
            )
          )
        } else if (child.children) {
          const grandChildren =
            typeof child.children === 'function'
              ? child.children()
              : child.children

          if (!grandChildren.length) {
            continue
          }

          processedChildren.push(
            new Submenu(child.label, () =>
              grandChildren.map(itm => ({
                ...itm,
                value: {
                  ...itm.value,
                  source: {
                    ...itm.value.source,
                    source: opt.value,
                  },
                },
              }))
            )
          )
        }
      }

      item.children = processedChildren

      if (item.children.length > 0) {
        results.push(item)
      }
    })

    return addBindings(results, [bindingTypes.LIBRARY_PROP])
  }

// Get binding suggestions for library component props

export const getLibraryBindingSuggestions = (
  state,
  appId,
  componentId,
  objectId,
  allowedDataTypes,
  reference,
  actionId,
  inputName,
  helpers = {},
  hideParentListOptions
) => {
  const includeLiterals = allowedDataTypes.includes('boolean')

  const sources = getSources(
    state,
    appId,
    componentId,
    objectId,
    allowedDataTypes,
    null,
    actionId,
    reference,
    includeLiterals,
    undefined,
    inputName,
    false,
    helpers,
    hideParentListOptions
  )

  return addBindings(sources, [bindingTypes.LIBRARY_PROP])
}

export const getExternalAPIBindingSuggestions = (
  state,
  appId,
  allowedDataTypes
) => {
  const sources = () => {
    const loggedInUser = getLoggedInUserForScreen({
      state,
      appId,
      allowedDataTypes,
      allowedObjectTypes: [],
      allowedListTypes: [],
    })

    return getLoggedInUserMenu(loggedInUser)
  }

  return addBindings(sources, [bindingTypes.SET_TEXT])
}

// Get table binding for all

export const getLibraryAllBinding = (datasourceId, tableId, options = {}) => {
  const source = buildTableSource({ datasourceId, tableId })
  const binding = buildBinding(bindingTypes.LIBRARY_PROP, source)

  binding.options = options

  return binding
}

// addBindings / addBindingsSub are co-recursion

export const addBindings = (options, allowedBindingTypes) => {
  if (typeof options === 'function') {
    return () => addBindings(options(), allowedBindingTypes)
  }

  return options.map(opt => addBindingsSub(opt, allowedBindingTypes))
}

export const addBindingsSub = (option, allowedBindingTypes) => {
  if (!option) {
    return option
  }

  if (option.children) {
    option = {
      ...option,
      children: addBindings(option.children, allowedBindingTypes),
    }
  }

  if (option?.value?.dataType) {
    const { dataType } = option.value

    const bindingType = bestBindingType(allowedBindingTypes, dataType)
    const binding = buildBinding(bindingType, option.value)

    option = { ...option, value: binding }
  }

  return option
}

// Filters

export const getFilterOptions = (
  state,
  appId,
  datasourceId,
  tableId,
  objectId,
  bindingId
) => {
  const baseSource = buildListItemSource({
    datasourceId,
    tableId,
    listObjectId: bindingId,
  })

  const simpleDataTypes = [
    dataTypes.TEXT,
    dataTypes.NUMBER,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.BOOLEAN,
    dataTypes.LIST,
    dataTypes.LOCATION,
  ]

  const datasource = getDatasource(state, appId, datasourceId)
  const table = getTable(state, appId, datasourceId, tableId)
  if (!table) return []

  let result = getTableFieldOptions(
    state,
    table,
    baseSource,
    datasource,
    simpleDataTypes
  )

  result = result.map(itm => {
    if (!itm) {
      return itm
    } else if (itm.value && itm.value.fieldId) {
      return {
        ...itm,
        value: itm.value.fieldId,
      }
    }

    return {
      ...itm,
      children: addBindings(itm.children, [bindingTypes.LIBRARY_PROP]),
    }
  })

  return result
}

// Real beginning of data bindings...

export const getListOptions = opts => () => {
  const { state, appId, componentId, objectId, binding } = opts

  if (binding.source.collectionId) {
    // TODO: Support APIs
    return []
  }

  const { datasourceId, tableId } = getTableInfo(binding.source)

  const table = getTable(state, appId, datasourceId, tableId)
  if (!table) return []
  const tableName = table.name.toLowerCase()

  const allSource = buildTableSource({
    datasourceId,
    tableId,
  })

  let options = [new MenuOption(`All ${tableName}`, allSource, 'collections')]

  const suggestions = getSources(state, appId, componentId, objectId, [
    { type: dataTypes.LIST, tableId },
  ])()

  options = options.concat(suggestions)
  options = evaluateOptions(options, 4)

  options = flattenOptions(options)
  options = uniqueElements(options, opt => opt && opt.value, true)

  options = addBindings(options, [bindingTypes.LIBRARY_PROP])

  options = options.map(opt => ({
    ...opt,
    value: { ...opt.value, id: binding.id },
  }))

  options.push(null)

  options.push({
    label: MORE_MENU_LABEL,
    children: addBindings(suggestions, [bindingTypes.LIBRARY_PROP]),
  })

  return options
}

export const evaluateOptions = (options, depth = 2) => {
  if (depth <= 0) {
    return options
  }

  if (typeof options === 'function') {
    options = options()
  }

  return options.map(opt => {
    if (opt && opt.children) {
      return {
        ...opt,
        children: evaluateOptions(opt.children, depth - 1),
      }
    }

    return opt
  })
}

export const flattenOptions = (
  options,
  baseLabel = null,
  removeChildren = true
) => {
  const results = []

  if (!options) {
    return options
  }

  for (const option of options) {
    if (!option) {
      continue
    }

    if (
      Array.isArray(option.children) &&
      (option.inline || option.label === MORE_MENU_LABEL)
    ) {
      results.push(
        ...flattenOptions(option.children, baseLabel, removeChildren)
      )

      continue
    }

    let label

    if (baseLabel) {
      if (option.label === 'All') {
        label = baseLabel
      } else {
        label = `${baseLabel} > ${option.label}`
      }
    } else {
      label = option.label
    }

    label = stripPossessive(label)

    if (option.value) {
      results.push({
        ...option,
        label,
      })
    }

    if (option.children && Array.isArray(option.children)) {
      results.push(...flattenOptions(option.children, label))
    }
  }

  if (removeChildren) {
    results.forEach(result => {
      if (result) {
        result.children = null
      }

      return result
    })
  }

  return results
}

// Used for list sorting in library components
export const getSortOptions = opts => {
  const { state, appId, binding } = opts

  if (binding && binding.source && binding.source.collectionId) {
    return []
  }

  const { datasourceId, tableId } = getTableInfo(binding.source)

  const table = getTable(state, appId, datasourceId, tableId)

  if (!table) return []

  const fields = getFields(table, state)

  const simpleTypes = [
    dataTypes.TEXT,
    dataTypes.NUMBER,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.BOOLEAN,
    dataTypes.LOCATION,
  ]

  const options = []

  options.push(new MenuOption('None', null, 'none'))
  options.push(null)

  for (const fieldId of getFieldIds(fields)) {
    const field = fields[fieldId]

    if (simpleTypes.includes(field.type)) {
      const ascLabel = getSortLabel(field.type, sortDirections.ASC)
      const descLabel = getSortLabel(field.type, sortDirections.DESC)

      options.push(
        new MenuOption(
          `${field.name} - ${ascLabel}`,
          { fieldId, direction: sortDirections.ASC, type: field.type },
          `sort-${field.type}-asc`
        )
      )

      options.push(
        new MenuOption(
          `${field.name} - ${descLabel}`,
          { fieldId, direction: sortDirections.DESC, type: field.type },
          `sort-${field.type}-desc`
        )
      )
    }
  }

  return options
}

const getCollectionDataMenus = (
  args,
  datasource,
  parentListBindings,
  contextualTablesForScreen,
  loggedInUserForScreen
) => {
  const parentListMenus = getParentListMenus(parentListBindings)

  const contextualTableMenus = getContextualTableMenus(
    contextualTablesForScreen
  )

  const menuItems = [...parentListMenus, ...contextualTableMenus]

  if (datasource?.type === 'api') {
    return menuItems
  }

  const loggedInUserMenu = getLoggedInUserMenu(loggedInUserForScreen)
  const createdObjectItems = getCreatedObjectItems(args, getCreatedObjectMenu)
  const createdObjectFormMenu = getCreatedObjectFormMenu(args)

  return [
    ...menuItems,
    ...loggedInUserMenu,
    ...createdObjectItems,
    ...createdObjectFormMenu,
  ]
}

const getCollectionDataOptions = (
  args,
  datasource,
  parentListBindings,
  contextualTablesForScreen,
  loggedInUserForScreen
) => {
  const { state } = args

  const parentListOptions = getParentListOptions({
    ...parentListBindings,
    state,
  })
  const contextualTableOptions = getContextualTableOptions(
    contextualTablesForScreen
  )

  const menuItems = [...parentListOptions, ...contextualTableOptions]

  if (datasource?.type === 'api') {
    return menuItems
  }

  const loggedInUserOption = getLoggedInUserOption(loggedInUserForScreen)
  const createdObjectItems = getCreatedObjectItems(args, getCreatedObjectOption)
  const createdObjectFormOption = getCreatedObjectFormOption(args)

  return [
    ...menuItems,
    ...loggedInUserOption,
    ...createdObjectItems,
    ...createdObjectFormOption,
  ]
}

const getAllowedTypes = allowedTypes => {
  const complexTypes = allowedTypes.filter(t => t.type)
  const simpleTypes = allowedTypes.filter(t => !t.type)

  const allowedObjectTypes = complexTypes
    .filter(t => t.type === dataTypes.OBJECT || t.type === 'belongsTo')
    .map(opt => opt.tableId || opt.collectionId)

  const allowedListTypes = complexTypes
    .filter(
      t =>
        t.type === dataTypes.LIST ||
        t.type === 'hasMany' ||
        t.type.includes('manyToMany')
    )
    .map(opt => opt.tableId)

  return { allowedDataTypes: simpleTypes, allowedObjectTypes, allowedListTypes }
}

const nestOptions = (baseOptions, toBeNestedOptions) => {
  const result = [...baseOptions]

  if (toBeNestedOptions.length) {
    if (baseOptions.length) {
      // hide the menu items inside of More if there are other things in the menu
      result.push(null, new Submenu(MORE_MENU_LABEL, toBeNestedOptions))
    } else {
      // display the menu items at the top level if they are no other menu items
      result.push(...toBeNestedOptions)
    }
  }

  return result
}

const getCurrentDeviceLocationFieldOptions = allowedDataTypes =>
  allowedDataTypes
    .filter(allowedDataType =>
      Object.keys(LOCATION_OPTIONS).includes(allowedDataType)
    )
    .flatMap(dataType =>
      LOCATION_OPTIONS[dataType].map(({ label, fieldId }) => {
        const source = buildDeviceLocationSource({
          dataType,
          fieldId,
        })

        return new MenuOption(label, source, getFieldIcon(dataType))
      })
    )

const disabledCurrentLocationOption =
  (label, handleTrial = () => {}, hoverContent) =>
  icon => ({
    label,
    locked: true,
    rightIcon: <Icon type="lock-small" small />,
    onClick: handleTrial('geolocation'),
    hoverContent,
    icon,
  })

// Current device location in magic text
const getDeviceLocationBinding = (state, appId, allowedTypes, helpers) => {
  const { handleTrial, hoverContent } = helpers
  const isLocationEnabled = isFeatureEnabled(state, 'geolocation')

  const result = []
  const { allowedDataTypes } = allowedTypes
  const label = 'Current Device Location'

  const disabledOption = disabledCurrentLocationOption(
    label,
    handleTrial,
    hoverContent
  )

  const googleApiKey = getThirdPartyApiKeyFor(state, appId, 'google')
  const googleApiKeyIsValid = googleApiKey && googleApiKey.isValid

  if (allowedDataTypes.includes(dataTypes.LOCATION)) {
    if (!isLocationEnabled) {
      result.push(disabledOption('my-location'))
    } else {
      if (googleApiKeyIsValid) {
        result.push(
          new MenuOption(
            label,
            {
              type: sourceTypes.DEVICE_LOCATION,
              dataType: dataTypes.LOCATION,
            },
            'my-location'
          )
        )
      } else {
        result.push({
          label,
          icon: 'my-location',
          rightIcon: GoogleTooltip,
        })
      }
    }
  }

  if (
    allowedDataTypes.includes(dataTypes.TEXT) ||
    allowedDataTypes.includes(dataTypes.NUMBER)
  ) {
    if (!isLocationEnabled) {
      result.push(disabledOption())
    } else {
      if (googleApiKeyIsValid) {
        result.push(
          new Submenu(
            label,
            getCurrentDeviceLocationFieldOptions(allowedDataTypes)
          )
        )
      } else {
        result.push({
          label,
          children: [],
          rightIcon: GoogleTooltip,
        })
      }
    }
  }

  return result
}
const getInputLocationBinding = (
  state,
  appId,
  allowedTypes,
  customLocation
) => {
  const result = []

  if (!customLocation) {
    return result
  }

  const { allowedDataTypes } = allowedTypes
  const label = 'Custom Location'

  const googleApiKey = getThirdPartyApiKeyFor(state, appId, 'google')
  const googleApiKeyIsValid = googleApiKey && googleApiKey.isValid

  if (allowedDataTypes.includes(dataTypes.LOCATION) && googleApiKeyIsValid) {
    result.push(
      new MenuOption(
        label,
        {
          type: sourceTypes.CUSTOM_LOCATION,
          dataType: dataTypes.LOCATION,
          label,
        },
        `location`
      )
    )
  }

  return result
}

export const getSources =
  (
    state,
    appId,
    componentId,
    objectId,
    allowedDataTypes,
    excludeDataSources,
    actionId,
    reference,
    includeLiterals = false,
    filterFunc = sources => sources,
    inputName = '',
    customLocation = false,
    helpers = {},
    hideParentListOptions = false
  ) =>
  () => {
    const allowedTypes = getAllowedTypes(allowedDataTypes)

    const args = {
      state,
      appId,
      componentId,
      objectId,
      actionId,
      ...allowedTypes,
    }

    if (reference) {
      const options = []

      if (includeLiterals) {
        options.push(...getLiteralBindings(args))
      }

      const embeddedMenuItems = getListChildBindings({
        state,
        appId,
        objectId,
        reference,
        sourcesOnly: true,
        ...allowedTypes,
      })()

      const filteredEmbeddedMenuItems = filterFunc(embeddedMenuItems)

      if (options.length && filteredEmbeddedMenuItems.length) {
        options.push(null)
      }

      options.push(...filterFunc(embeddedMenuItems))

      const children = getSources(
        state,
        appId,
        componentId,
        objectId,
        allowedDataTypes,
        excludeDataSources,
        actionId,
        undefined,
        false,
        filterFunc,
        '',
        false,
        helpers,
        hideParentListOptions
      )()

      return nestOptions(options, children)
    }

    let options = []

    const actionArguments = getActionArguments(
      state,
      objectId,
      actionId,
      appId,
      allowedDataTypes
    )

    const otherComponentInputBindings = getOtherComponentBindings(args)

    const thisComponentInputBindings = getThisComponentBindings(
      args,
      inputName,
      actionArguments
    )

    if (includeLiterals) {
      options.push(...getLiteralBindings(args))
    }

    const paramBindings = getParamBindings(args)

    if (options.length && paramBindings.length) {
      options.push(null)
    }

    options.push(...paramBindings)

    const datasource = getDatasources(state, appId)[0]

    const parentListBindings = getParentListBindings({
      ...args,
      hideParentListOptions,
    })
    const contextualTablesForScreen = getContextualTablesForScreen(args)
    const loggedInUserForScreen = getLoggedInUserForScreen(args)

    const menuOptions = getCollectionDataOptions(
      args,
      datasource,
      parentListBindings,
      contextualTablesForScreen,
      loggedInUserForScreen
    )

    if (!excludeDataSources) {
      if (options.length && menuOptions.length) {
        options.push(null)
      }

      options.push(...menuOptions)
    }

    // if there are any clickable collection menu items,
    // nest expandable collection menu items into More
    const flatCollections = !menuOptions.length

    // Get Collection Menu Items with Submenus
    const menuItems = filterFunc(
      getCollectionDataMenus(
        args,
        datasource,
        parentListBindings,
        contextualTablesForScreen,
        loggedInUserForScreen
      )
    )

    if (flatCollections) {
      if (options.length && menuItems.length) {
        options.push(null)
      }

      options.push(...menuItems)
    }

    const deviceLocationBinding = getDeviceLocationBinding(
      state,
      appId,
      allowedTypes,
      helpers
    )

    if (options.length && deviceLocationBinding.length) {
      options.push(null)
    }

    options.push(...deviceLocationBinding)

    const inputLocationBinding = getInputLocationBinding(
      state,
      appId,
      allowedTypes,
      customLocation
    )

    options.push(...inputLocationBinding)

    const customActionSources = getCustomActionSources(args, helpers)

    if (options.length && customActionSources.length) {
      options.push(null)
    }

    options.push(...customActionSources)

    // if there are form inputs, add a spacer (null), and append the form input
    options = [
      ...options,
      ...(options.length &&
      (otherComponentInputBindings.length || thisComponentInputBindings.length)
        ? [null]
        : []),
      ...thisComponentInputBindings,
      ...otherComponentInputBindings,
    ]

    const secondarySources = getSecondarySources(
      state,
      appId,
      componentId,
      objectId,
      allowedDataTypes,
      excludeDataSources,
      actionId
    )

    if (options.length && secondarySources.length) {
      options.push(null)
    }

    options.push(...secondarySources)

    // no additional work needs to be done
    // if we're not nesting collections into 'More...' at the bottom

    if (flatCollections) {
      return options
    }

    return nestOptions(options, menuItems)
  }

const getSecondarySources = (
  state,
  appId,
  componentId,
  objectId,
  allowedDataTypes,
  excludeDataSources,
  actionId
) => {
  const args = {
    state,
    appId,
    componentId,
    objectId,
    actionId,
    ...getAllowedTypes(allowedDataTypes),
  }

  const result = []

  if (
    allowedDataTypes.includes(dataTypes.DATE) ||
    allowedDataTypes.includes(dataTypes.DATE_ONLY)
  ) {
    result.push(getDateTimeSources(allowedDataTypes))
  }

  if (excludeDataSources) {
    return result
  }

  // TODO: Allow for multiple datasources
  const datasource = getDatasources(state, appId)[0]

  const sources =
    datasource.type === 'api' ? getAPISources(args) : getTableBindings(args)

  if (sources.length) {
    if (result.length) {
      result.push(null)
    }

    result.push(...sources)
  }

  return result
}

const getActionArguments = (
  state,
  objectId,
  actionId,
  appId,
  allowedDataTypes
) => {
  const object = selectObject(state, objectId)
  const actionName = findActionName(state, object, actionId)
  if (!actionName) return null
  const libraryName = object.libraryName

  return getArgumentsFromActionName(
    appId,
    libraryName,
    actionName,
    allowedDataTypes
  )
}

const findActionName = (state, object, actionId) => {
  if (!object || !object.actions) return ''
  const { actions, attributes } = object
  let targetActionChainId = ''
  const actionChainIds = Object.keys(actions)

  for (
    let actionChainInd = 0;
    actionChainInd < actionChainIds.length;
    actionChainInd = actionChainInd + 1
  ) {
    const actionChainId = actionChainIds[actionChainInd]
    const actionChain = actions[actionChainId]
    const actionArray = actionChain.actions

    for (const action of actionArray || []) {
      if (action.id === actionId) {
        targetActionChainId = actionChainId
      }
    }
  }

  if (targetActionChainId !== '') {
    if (attributes) {
      const attributesKeys = Object.keys(attributes)

      for (
        let attributesInd = 0;
        attributesInd < attributesKeys.length;
        attributesInd = attributesInd + 1
      ) {
        const attributeName = attributesKeys[attributesInd]
        const attribute = attributes[attributeName]
        if (!attribute) continue

        if (
          attribute?.type === 'actionRef' &&
          attribute?.actionId === targetActionChainId
        ) {
          return attributeName
        }

        const attributeKeys = Object.keys(attribute)

        for (
          let attributeInd = 0;
          attributeInd < attributeKeys.length;
          attributeInd = attributeInd + 1
        ) {
          const childAttributeName = attributeKeys[attributeInd]
          const childAttribute = attribute[childAttributeName]

          if (
            childAttribute?.type === 'actionRef' &&
            childAttribute?.actionId === targetActionChainId
          ) {
            return attributeName.concat('.', childAttributeName)
          }
        }
      }
    }
  }

  return ''
}

const getArgumentsFromActionName = (
  appId,
  libraryName,
  actionName,
  allowedDataTypes
) => {
  const library = getAppLibrary(appId, libraryName)

  if (library) {
    const actionArguments = {
      label: library.config.displayName,
      children: [],
    }

    const {
      config: { components },
    } = library

    components.forEach(component => {
      const { props } = component

      props.forEach(prop => {
        if (prop.name === actionName && prop.arguments) {
          for (const [i, actionArgument] of prop.arguments.entries()) {
            const { displayName, type, fieldId } = actionArgument

            if (allowedDataTypes.includes(type)) {
              const source = buildActionArgumentSource({
                type,
                libraryName,
                i,
                fieldId,
                displayName,
              })

              if (source) {
                const newArgument = {
                  label: displayName,
                  value: source,
                }

                actionArguments.children.push(newArgument)
              }
            }
          }
        }
      })

      const { childComponents } = component

      if (childComponents) {
        childComponents.forEach(childComponent => {
          const { props, name } = childComponent

          props.forEach(prop => {
            const testName = name.concat('.', prop.name)

            if (testName === actionName && prop.arguments) {
              for (const [i, actionArgument] of prop.arguments.entries()) {
                const { displayName, type, fieldId } = actionArgument

                if (allowedDataTypes.includes(type)) {
                  const source = buildActionArgumentSource({
                    type,
                    libraryName,
                    i,
                    fieldId,
                    displayName,
                  })

                  if (source) {
                    const newArgument = {
                      label: displayName,
                      value: source,
                    }

                    actionArguments.children.push(newArgument)
                  }
                }
              }
            }
          })
        })
      }
    })

    if (actionArguments.children.length !== 0) {
      return actionArguments
    }
  }

  return null
}

// Generates a list of menu items for New objects
const getCreatedObjectItems = (
  {
    state,
    appId,
    objectId,
    actionId,
    allowedObjectTypes,
    allowedDataTypes = [],
    includeCurrentAction = false,
  },
  getMenuItem
) => {
  const result = []

  if (!actionId) {
    return result
  }

  const object = selectObject(state, objectId)
  const { actions: componentActions = {} } = object
  const { componentActions: screenActions = {} } = getComponent(state, objectId)

  const actions = { ...componentActions, ...screenActions }

  for (const eventId of Object.keys(actions)) {
    const eventObj = actions[eventId]
    const prevCreateActions = []

    if (!eventObj.actions) {
      continue
    }

    for (const action of eventObj.actions) {
      const { id: currentActionId, actionType, options = {} } = action
      const { datasourceId, tableId } = options

      const isActionTypeCreateObject =
        actionType === actionTypes.CREATE_OBJECT && datasourceId && tableId

      if (currentActionId === actionId) {
        if (isActionTypeCreateObject && includeCurrentAction) {
          prevCreateActions.push(`${datasourceId}.${tableId}`)
        }

        uniqueElements(prevCreateActions).forEach(createAction =>
          result.push(
            ...getMenuItem(
              createAction,
              state,
              appId,
              allowedDataTypes,
              allowedObjectTypes
            )
          )
        )
      }

      if (isActionTypeCreateObject) {
        prevCreateActions.push(`${datasourceId}.${tableId}`)
      }
    }
  }

  return result
}

/**
 * @param {String} createAction - datasourceId and tableId connected by a dot(.)
 * @param {Object} state
 * @param {String} appId
 * @param {Array} allowedDataTypes
 * @param {Array} allowedObjectTypes
 * @returns {Array} - a menu item for a New object that is selectable (not expandable)
 */
const getCreatedObjectOption = (
  createAction,
  state,
  appId,
  allowedDataTypes,
  allowedObjectTypes
) => {
  const [prevActionDatasourceId, prevActionTableId] = createAction.split('.')

  const table = getTable(
    state,
    appId,
    prevActionDatasourceId,
    prevActionTableId
  )

  const tableHasValue =
    allowedDataTypes.includes(dataTypes.OBJECT) ||
    allowedObjectTypes?.includes(prevActionTableId)

  if (!table || !tableHasValue) {
    return []
  }

  return [
    new MenuOption(
      `New ${singularize(capitalize(table.name))}`,
      buildCreatedObjectSource({
        datasourceId: prevActionDatasourceId,
        tableId: prevActionTableId,
      }),
      'relationship-single'
    ),
  ]
}

/**
 * @param {String} createAction - datasourceId and tableId connected by a dot(.)
 * @param {Object} state
 * @param {String} appId
 * @param {Array} allowedDataTypes
 * @param {Array} allowedObjectTypes
 * @returns {Array} - a menu item for a New object that is expandable (not selectable)
 */
const getCreatedObjectMenu = (
  createAction,
  state,
  appId,
  allowedDataTypes,
  allowedObjectTypes
) => {
  const [prevActionDatasourceId, prevActionTableId] = createAction.split('.')

  const table = getTable(
    state,
    appId,
    prevActionDatasourceId,
    prevActionTableId
  )

  if (!table) {
    return []
  }

  const datasource = getDatasource(state, appId, prevActionDatasourceId)

  const source = buildCreatedObjectSource({
    datasourceId: prevActionDatasourceId,
    tableId: prevActionTableId,
  })

  const label = `New ${possessiveExpansion(
    singularize(capitalize(table.name))
  )}`

  const children = getTableFieldOptions(
    state,
    table,
    source,
    datasource,
    allowedDataTypes,
    allowedObjectTypes
  )

  return [new Submenu(label, [{ label, inline: true, children }])]
}

const getActionIsForFormSecondaryButton = (formObject, actionId) => {
  // If the action is part of the secondaryButton actions, then don't show the New object menu
  const secondaryButtonActionId = formObject?.secondaryButton?.action?.actionId
  let secondaryButtonActions = []
  let isSecondaryButtonAction = false
  if (actionId && secondaryButtonActionId) {
    secondaryButtonActions =
      formObject?.actions?.[secondaryButtonActionId]?.actions?.map(a => a.id) ??
      []
    isSecondaryButtonAction = secondaryButtonActions.includes(actionId)
  }

  return isSecondaryButtonAction
}

// Hack for Forms: returns a menu item for a New object that is selectable (not expandable)
const getCreatedObjectFormOption = ({
  state,
  appId,
  objectId,
  actionId,
  allowedObjectTypes,
  allowedDataTypes = [],
}) => {
  const result = []

  const formObject = selectObject(state, objectId) || {}
  const { type, reference, collection = {} } = formObject

  // If the action is part of the secondaryButton actions, then don't show the New object menu
  const isSecondaryButtonAction = getActionIsForFormSecondaryButton(
    formObject,
    actionId
  )

  if (
    !actionId ||
    type !== FORM ||
    reference !== 'new' ||
    isSecondaryButtonAction
  ) {
    return result
  }

  const { datasourceId, tableId } = collection
  const table = getTable(state, appId, datasourceId, tableId)

  const tableHasValue =
    allowedDataTypes.includes(dataTypes.OBJECT) ||
    allowedObjectTypes?.includes(tableId)

  if (!tableHasValue) {
    return result
  }

  result.push(
    new MenuOption(
      `New ${singularize(capitalize(table?.name))}`,
      buildCreatedObjectSource({ datasourceId, tableId }),
      'relationship-single'
    )
  )

  return result
}

// Hack for Forms: returns a menu item for a New object for a form that is expandable (not selectable)
const getCreatedObjectFormMenu = ({
  state,
  appId,
  objectId,
  actionId,
  allowedObjectTypes,
  allowedDataTypes = [],
}) => {
  const result = []

  const formObject = selectObject(state, objectId) || {}
  const { type, reference, collection = {} } = formObject

  // If the action is part of the secondaryButton actions, then don't show the New object menu
  const isSecondaryButtonAction = getActionIsForFormSecondaryButton(
    formObject,
    actionId
  )

  if (
    !actionId ||
    type !== FORM ||
    reference !== 'new' ||
    isSecondaryButtonAction
  ) {
    return result
  }

  const { datasourceId, tableId } = collection
  const table = getTable(state, appId, datasourceId, tableId)
  const tableFieldOptions = getTableFieldOptions(
    state,
    table,
    buildCreatedObjectSource({ datasourceId, tableId }),
    getDatasource(state, appId, datasourceId),
    allowedDataTypes,
    allowedObjectTypes
  )

  result.push(
    new Submenu(
      `New ${singularize(capitalize(table?.name))}`,
      tableFieldOptions
    )
  )

  return result
}

// Dates

const DATE_AND_TIME_LABEL = 'Date & Time'

/**
 *
 * @returns {Submenu}
 */

export const getDateTimeSources = () => {
  const options = [
    new MenuOption('None', buildDateTimeSource({ none: true }), 'date-none'),
    new MenuOption(
      'Current Time',
      buildDateTimeSource(),
      'date-and-time-range'
    ),
    new MenuOption(
      'Start of Today',
      buildDateTimeSource({ startOfDay: true }),
      'date-range'
    ),
  ]

  // More
  const offsets = dateTimeOffsets

  const relativeOptions = offsets.map(itm => {
    if (!itm) {
      return itm
    }

    const [offset, label, icon] = itm

    return new MenuOption(
      label,
      buildDateTimeSource({
        offset,
        startOfDay: Math.abs(offset) >= 1,
      }),
      icon
    )
  })

  options.push(null)

  options.push(new Submenu(MORE_MENU_LABEL, relativeOptions))

  return new Submenu(DATE_AND_TIME_LABEL, options)
}

/**
 *
 * @returns {Array<MenuOption>}
 */
const getDateTimeBasicOptions = () => {
  const options = [
    new MenuOption('None', buildDateTimeSource({ none: true }), 'date-none'),
    new MenuOption(
      'Current Time',
      buildDateTimeSource(),
      'date-and-time-range'
    ),
    new MenuOption(
      'Start of Today',
      buildDateTimeSource({ startOfDay: true }),
      'date-range'
    ),
  ]

  return options
}

/**
 *
 * @returns {Submenu}
 */
const getDateTimeRelativeSubmenu = () => {
  const offsets = dateTimeOffsets

  const relativeOptions = offsets.map(itm => {
    if (!itm) {
      return itm
    }

    const [offset, label, icon] = itm

    return new MenuOption(
      label,
      buildDateTimeSource({
        offset,
        startOfDay: Math.abs(offset) >= 1,
      }),
      icon
    )
  })

  const relativeSubmenu = new Submenu(MORE_MENU_LABEL, relativeOptions)

  return relativeSubmenu
}

/**
 *
 * @returns {Array<MenuOption | null>}
 */
export const getDateTimeContextualSources = (
  state,
  appId,
  componentId,
  objectId
) => {
  const allowedDataTypes = [dataTypes.DATE, dataTypes.DATE_ONLY]
  const sources = getSources(
    state,
    appId,
    componentId,
    objectId,
    allowedDataTypes,
    true
  )()

  // getSources will return the default Date & Time options too, but we want to put those before the contextual sources,
  // so we filter them out here, and add them back in the correct order.
  // We also filter out "Other Components" since getting that to work in runner's Date component is a bit more tricky. We'll do it later.
  const filteredSources = sources.filter(
    s =>
      s &&
      s?.label !== DATE_AND_TIME_LABEL &&
      s?.label !== OTHER_COMPONENTS_MENU_LABEL
  )

  const basicOptions = getDateTimeBasicOptions()
  const relativeSubmenu = getDateTimeRelativeSubmenu()

  return [...basicOptions, null, ...filteredSources, null, relativeSubmenu]
}

// Inputs
export const getInputBindingsSub = (
  state,
  componentId,
  objectId,
  allowedDataTypes,
  allowedObjectTypes,
  removeCurrentComponent = false,
  removeOtherComponent = false,
  thisPropName
) => {
  const inputIds = []

  // Inputs
  if (
    allowedDataTypes.includes(dataTypes.TEXT) ||
    allowedDataTypes.includes(dataTypes.NUMBER)
  ) {
    inputIds.push(...getInputs(state, componentId, objectId))
  }

  let imagePickers = getImagePickers(state, componentId)

  if (removeCurrentComponent) {
    imagePickers = imagePickers.filter(
      imagePicker => imagePicker.objectId !== objectId
    )
  }

  if (removeOtherComponent) {
    imagePickers = imagePickers.filter(
      imagePicker => imagePicker.objectId === objectId
    )
  }

  if (allowedDataTypes.includes(dataTypes.IMAGE)) {
    inputIds.push(...imagePickers)
  }

  // Date Pickers
  if (
    allowedDataTypes.includes(dataTypes.DATE) ||
    allowedDataTypes.includes(dataTypes.DATE_ONLY)
  ) {
    inputIds.push(...getDatePickers(state, componentId))
  }

  // Boolean Inputs
  if (allowedDataTypes.includes(dataTypes.BOOLEAN)) {
    inputIds.push(...getCheckboxes(state, componentId))
  }

  // Rich Object Inputs
  const filePickers = getFilePickers(state, componentId).filter(
    filePicker =>
      (!removeCurrentComponent && !removeOtherComponent) ||
      (removeCurrentComponent && filePicker.objectId !== objectId) ||
      (removeOtherComponent && filePicker.objectId === objectId)
  )

  // add inputs
  if (allowedDataTypes.includes(dataTypes.FILE)) {
    inputIds.push(...filePickers)
  }
  const locationInputs = getLocationInputs(state, componentId).filter(
    locationInput =>
      (!removeCurrentComponent && !removeOtherComponent) ||
      (removeCurrentComponent && locationInput.objectId !== objectId) ||
      (removeOtherComponent && locationInput.objectId === objectId)
  )
  if (allowedDataTypes.includes(dataTypes.LOCATION)) {
    inputIds.push(...locationInputs)
  }

  const filteredInputIds = inputIds.filter(input => {
    if (removeCurrentComponent) {
      return input.objectId ? input.objectId !== objectId : input !== objectId
    }
    if (removeOtherComponent) {
      return input.objectId && input.prop
        ? input.objectId === objectId && input.prop[0] !== thisPropName
        : input === objectId
    }

    return true
  })

  const options = []

  if (removeCurrentComponent) {
    const inputs =
      filteredInputIds.length === 0
        ? []
        : filteredInputIds.reduce((outputs, input) => {
            // const parentObjectId = input.objectId
            if (input.objectId) {
              outputs[input.objectId] = [
                ...(outputs[input.objectId] || []),
                input,
              ]
            } else {
              outputs[input] = [...(outputs[input] || []), input]
            }

            return outputs
          }, {})
    for (const parentObjectId in inputs) {
      if (!parentObjectId) {
        continue
      }

      const childInputs = inputs[parentObjectId]

      const children = childInputs.map(inputPath => {
        let inputId = inputPath

        if (Array.isArray(inputId)) {
          inputId = inputPath[inputPath.length - 1]
        }

        const { objectId, prop, dataType } = inputId

        if (objectId && prop && dataType) {
          const object = selectObject(state, objectId)
          const app = getApp(state, getCurrentAppId(state))
          const source = buildLibraryInputSource(objectId, prop, dataType)
          const label = getLibraryPropLabel(app, object, prop, false)

          return new MenuOption(label, source, getFieldIcon(source.dataType))
        } else {
          const obj = selectObject(state, inputId)

          if (!obj) {
            return
          }

          const label = getObjectName(obj)

          const source = buildInputSource({
            inputObject: obj,
            inputId: inputPath,
          })

          return new MenuOption(
            label,
            { ...source, label },
            getFieldIcon(source.dataType)
          )
        }
      })

      const object = selectObject(state, parentObjectId)

      if (!object) {
        children
          .filter(child => !!child)
          .forEach(child => {
            options.push(child)
          })

        continue
      }

      const parentLabel = getObjectName(object)

      if (object.type === 'libraryComponent') {
        options.push(new Submenu(parentLabel, children))
      } else {
        const source = buildInputSource({
          inputObject: object,
          inputId: object.id,
        })

        options.push(
          new MenuOption(
            parentLabel,
            { ...source, parentLabel },
            getFieldIcon(source.dataType)
          )
        )
      }
    }
  } else {
    for (const inputPath of filteredInputIds) {
      let inputId = inputPath

      if (Array.isArray(inputId)) {
        inputId = inputPath[inputPath.length - 1]
      }

      if (inputId && typeof inputId === 'object') {
        const { objectId, prop, dataType } = inputId
        const object = selectObject(state, objectId)
        const app = getApp(state, getCurrentAppId(state))
        const source = buildLibraryInputSource(objectId, prop, dataType)
        const label = getLibraryPropLabel(app, object, prop, false)

        options.push(
          new MenuOption(label, source, getFieldIcon(source.dataType))
        )
      }

      const obj = selectObject(state, inputId)

      if (!obj || obj.id === objectId) {
        continue
      }

      const label = getObjectName(obj)
      const source = buildInputSource({ inputObject: obj, inputId: inputPath })

      options.push(
        new MenuOption(
          label,
          { ...source, label },
          getFieldIcon(source.dataType)
        )
      )
    }
  }

  // Rich Object Sub-options
  if (
    allowedDataTypes.includes(dataTypes.TEXT) ||
    allowedDataTypes.includes(dataTypes.NUMBER)
  ) {
    const richObjectPickers = [
      ...filePickers,
      ...imagePickers,
      ...locationInputs,
    ]

    const richObjectInputs = richObjectPickers.reduce((outputs, input) => {
      if (input.objectId) {
        outputs[input.objectId] = [...(outputs[input.objectId] || []), input]
      } else {
        outputs[input] = [...(outputs[input] || []), input]
      }

      return outputs
    }, {})

    for (const parentObject in richObjectInputs) {
      if (parentObject) {
        const obj = selectObject(state, parentObject)
        const { libraryName, componentName, libraryVersion } = obj

        const manifest = getComponentInfo(
          libraryName,
          libraryVersion,
          componentName
        )

        const label = getObjectName(obj, undefined, undefined, state)

        const children = []
        let source

        for (const object of richObjectInputs[parentObject]) {
          if (typeof object === 'object') {
            let inputId = obj.id
            let prop

            if (object.prop.length === 1) {
              prop = object.prop[0]
              inputId = `${inputId}.${prop}`

              if (manifest) {
                prop = manifest.props.find(
                  libraryProp => libraryProp.name === prop
                )
              }
            } else if (object.prop.length === 2 && manifest) {
              for (const key in object.prop) {
                if (key) {
                  inputId = `${inputId}.${object.prop[key]}`
                }
              }

              const childComponent = manifest.childComponents.find(
                child => child.name === object.prop[0]
              )

              if (childComponent) {
                prop = childComponent.props.find(
                  childProp => childProp.name === object.prop[1]
                )
              }
            }

            const label = prop && prop.displayName ? prop.displayName : null
            source = buildInputSource({ inputObject: obj, inputId })

            children.push(
              new Submenu(
                label,
                getObjectFieldOptions(
                  source,
                  allowedDataTypes,
                  INPUT_OPTION_MAP[
                    obj.type === 'libraryComponent'
                      ? `${prop.type}-upload`
                      : obj.type
                  ]
                ),
                true
              )
            )
          } else {
            source = buildInputSource({ inputObject: obj })
          }
        }

        options.push(
          new Submenu(
            label,
            children.length > 0
              ? children
              : getObjectFieldOptions(
                  source,
                  allowedDataTypes,
                  INPUT_OPTION_MAP[obj.type]
                ),
            true
          )
        )
      }
    }
  }

  // Selects
  let selects = getSelects(state, componentId, objectId)

  if (removeCurrentComponent) {
    selects = selects.filter(select => select.objectId !== objectId)
  }

  if (removeOtherComponent) {
    selects = selects.filter(select => select.objectId === objectId)
  }

  for (const path of selects) {
    const appId = getCurrentAppId(state)
    const id = Array.isArray(path) ? path[path.length - 1] : path
    const obj = selectObject(state, id)
    const binding = obj?.select

    // bail if the binding is not a list type
    if (!binding?.source || binding.source.dataType !== dataTypes.LIST) {
      continue
    }

    const { datasourceId, tableId } = binding.source
    const table = getTable(state, appId, datasourceId, tableId)

    // bail if table is not defined
    if (!table) {
      continue
    }

    const source = buildSelectSource({
      datasourceId,
      tableId,
      selectObjectId: path,
    })

    const includeValue =
      allowedDataTypes.includes(dataTypes.OBJECT) ||
      allowedObjectTypes.includes(tableId)

    const selectName = getObjectName(obj)

    const datasource = getDatasource(state, appId, datasourceId)

    const tableName = singularize(capitalize(table.name || 'Untitled'))

    if (includeValue) {
      options.push(
        new Submenu(selectName, [
          new MenuOption(`Selected ${tableName}`, {
            ...source,
            selectName,
          }),
        ])
      )
    } else {
      options.push(
        new Submenu(selectName, () =>
          getTableFieldOptions(
            state,
            table,
            source,
            datasource,
            allowedDataTypes,
            allowedObjectTypes
          )
        )
      )
    }
  }

  // filter out anything without either a value or children
  return options.filter(
    option =>
      option.value ||
      (typeof option.children === 'function'
        ? option.children()
        : option.children
      ).length
  )
}

export const getThisComponentBindings = (opts, inputName, actionArguments) => {
  const { state, objectId, componentId, allowedDataTypes, allowedObjectTypes } =
    opts

  const thisComponent = selectObject(state, objectId)

  if (!thisComponent) {
    return []
  }

  const children = () => {
    const thisComponentInputBindings = getInputBindingsSub(
      state,
      componentId,
      objectId,
      allowedDataTypes,
      allowedObjectTypes,
      false,
      true,
      inputName
    )

    return [...thisComponentInputBindings]
  }

  const options = children().concat(actionArguments?.children || [])

  if (!options.length) {
    return []
  }

  return [new Submenu(thisComponent.name, options)]
}

export const getOtherComponentBindings = opts => {
  const {
    state,
    appId,
    componentId,
    objectId,
    allowedDataTypes,
    allowedObjectTypes,
  } = opts

  const children = () => {
    // get all input bindings for the current screen
    const currentScreenInputBindings = getInputBindingsSub(
      state,
      componentId,
      objectId,
      allowedDataTypes,
      allowedObjectTypes,
      true
    )

    // get all input bindings for all other screens
    const allScreenInputBindings = getScreens(state, appId)
      // filter out current screen
      .filter(screen => screen.id !== componentId)
      // construct the screen menu items with possible inputs related to current collection
      .map(screen => ({
        label: screen.name,
        children: () =>
          getInputBindingsSub(
            state,
            screen.id,
            null,
            allowedDataTypes,
            allowedObjectTypes,
            true
          ),
      }))
      // filter out screens without any inputs related to the current collection
      .filter(screen => screen.children().length > 0)

    const allScreensOption = {
      label: 'All Screens',
      children: allScreenInputBindings,
    }

    const needsSpacer =
      currentScreenInputBindings.length && allScreenInputBindings.length

    return [
      ...currentScreenInputBindings,
      ...(needsSpacer ? [null] : []),
      ...(allScreenInputBindings.length ? [allScreensOption] : []),
    ]
  }

  return [
    ...(children().length
      ? [new Submenu(OTHER_COMPONENTS_MENU_LABEL, children)]
      : []),
  ]
}

const disabledCustomActionOption = (
  label,
  handleTrial = () => {},
  hoverContent
) => ({
  label,
  locked: true,
  rightIcon: <Icon type="lock-small" small />,
  onClick: handleTrial('customIntegrations'),
  hoverContent,
})

export const getCustomActionSources = (args, helpers) => {
  let { state, appId, objectId, actionId, allowedDataTypes } = args

  if (!actionId) {
    return []
  }

  const results = []
  const obj = selectObject(state, objectId)
  const actions = obj.actions
  allowedDataTypes = allowedDataTypes || []

  for (const eventId of Object.keys(actions || {})) {
    const eventObj = actions[eventId]
    const prevCustomActions = []

    if (!eventObj || !eventObj.actions) {
      continue
    }

    for (const action of eventObj.actions) {
      if (action.id === actionId) {
        for (const actionId of uniqueElements(prevCustomActions)) {
          const customAction = getCustomAction(state, appId, actionId)
          const hasOutputs = customAction?.body?.outputs

          if (hasOutputs && !_isEmpty(hasOutputs)) {
            const label = customAction.body.name

            const isChatGPTAction =
              label === 'Ask ChatGPT' && customAction.body?.template

            if (isChatGPTAction) {
              const isCustomActionsEnabled = isFeatureEnabled(
                state,
                'customIntegrations'
              )

              if (!isCustomActionsEnabled) {
                const { handleTrial, hoverContent } = helpers

                const disabledOption = disabledCustomActionOption(
                  label,
                  handleTrial,
                  hoverContent
                )

                results.push(disabledOption)

                continue
              }
            }

            results.push({
              label,
              value: null,
              children: getCustomActionOutputOptions(
                customAction,
                allowedDataTypes
              ),
            })
          }
        }
      }

      if (action.actionType === actionTypes.CUSTOM) {
        const { customActionId } = action.options || {}

        if (customActionId) {
          prevCustomActions.push(customActionId)
        }
      }
    }
  }

  return results
}

export const getCustomActionOutputOptions = (
  customAction,
  allowedDataTypes
) => {
  const options = []
  const outputs = customAction.body.outputs

  const customActionId = customAction.id

  for (const itm of Object.keys(outputs)) {
    if (allowedDataTypes.includes(outputs[itm].type)) {
      const label = outputs[itm].name

      const value = buildCustomActionSource({
        ...outputs[itm],
        customActionId,
        outputId: itm,
      })

      options.push({
        label,
        value,
      })
    }
  }

  return options
}

// This returns data needed to construct parent list menu items (both clickable and nonclickable)
const getParentListBindings = ({
  state,
  appId,
  objectId,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
  hideParentListOptions,
}) => {
  const path = getPath(state, objectId)
  const numberOfPaths = pathLength(path)

  if (numberOfPaths === 1 || hideParentListOptions) {
    return { parentLists: [] }
  }

  const objects = selectObjects(state)
  const parents = []

  for (let i = 1; i < numberOfPaths; i += 1) {
    parents.push(getObject(objects, subPath(path, i)))
  }

  return {
    parentLists: parents.filter(
      parent => parent.dataBinding?.bindingType === bindingTypes.LIST
    ),
    datasources: getDatasourcesObject(state, appId),
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    state,
  }
}

// This returns an array of parent lists which are selectable (clickable) and without submenus
const getParentListOptions = ({
  parentLists,
  datasources,
  allowedDataTypes,
  allowedObjectTypes,
  state,
}) =>
  parentLists
    // filter out value-less items
    .filter(parent => {
      const sourceObject = buildSourceObject(parent.dataBinding.source)

      const sourceObjectDataId =
        sourceObject.usesCollections && sourceObject.usesCollections()
          ? sourceObject.getCollectionId()
          : sourceObject.getTableId()

      return (
        allowedDataTypes.includes(dataTypes.OBJECT) ||
        allowedObjectTypes.includes(sourceObjectDataId)
      )
    })
    .map(
      getParentListItem({
        datasources,
        allowedDataTypes,
        allowedObjectTypes,
        isOption: true,
        state,
      })
    )

// This returns an array of parent lists which are expandable (with submenus)
const getParentListMenus = ({
  parentLists,
  datasources,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
  state,
}) =>
  parentLists.map(
    getParentListItem({
      datasources,
      allowedDataTypes,
      allowedObjectTypes,
      allowedListTypes,
      state,
      isMenu: true,
    })
  )

// Returns a parent list item
const getParentListItem =
  ({
    datasources,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    state,
    isOption,
    isMenu,
  }) =>
  parent => {
    const binding = parent.dataBinding
    const sourceObject = buildSourceObject(binding.source)
    const datasourceId = sourceObject.getDatasourceId()
    const datasource = datasources[datasourceId]

    if (sourceObject.usesCollections && sourceObject.usesCollections()) {
      // API
      const collectionId = sourceObject.getCollectionId()
      const collection = datasource.collections[collectionId]

      const collectionLabel =
        binding.source.type === sourceTypes.API_FIELD
          ? binding.source.fieldId || '[deleted]'
          : capitalize(collection.name) || 'Untitled'

      const fields = sourceObject.getFields(state)

      const value = buildListItemSource({
        datasourceId,
        collectionId,
        listObjectId: parent.id,
      })

      const label = `${CURRENT} ${possessiveExpansion(
        singularize(collectionLabel)
      )}`

      const children = getEndpointFieldSourcesSub(
        value,
        fields,
        allowedDataTypes
      )

      if (isMenu) {
        return new Submenu(label, [{ label, inline: true, children }])
      } else if (isOption) {
        return new MenuOption(label, value, 'relationship-single')
      }
    }

    // Internal DB
    const tableId = sourceObject.getTableId()
    const table = datasource.tables[tableId]

    const tableSource = buildListItemSource({
      datasourceId,
      tableId,
      listObjectId: parent.id,
    })

    const tableName = singularize(
      capitalize((table && table.name) || 'Untitled')
    )

    if (isMenu) {
      const label = `${CURRENT} ${possessiveExpansion(tableName)}`

      const children = () => [
        {
          label,
          inline: true,
          children: getTableFieldOptions(
            state,
            table,
            tableSource,
            datasource,
            allowedDataTypes,
            allowedObjectTypes,
            allowedListTypes
          ),
        },
      ]

      return new Submenu(label, children)
    } else if (isOption) {
      const label = `${CURRENT} ${tableName}`
      const value = { ...tableSource, label }

      return new MenuOption(label, value, 'relationship-single')
    }
  }

export const getLiteralBindings = opts => {
  const { allowedDataTypes } = opts

  if (allowedDataTypes.includes(dataTypes.BOOLEAN)) {
    return [
      new MenuOption('True', true, 'checkbox'),
      new MenuOption('False', false, 'checkbox-empty'),
    ]
  }

  return []
}

export const getParamBindings = opts => {
  const {
    state,
    appId,
    componentId,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
  } = opts

  const params = getParams(state, appId, componentId)
  const allowedTypeSet = new Set(allowedDataTypes)
  const allowedObjectSet = new Set(allowedObjectTypes)

  const children = params.filter(param => {
    if (allowedTypeSet.has(param.type)) {
      return true
    }

    if (param && param.type && param.type.type === dataTypes.OBJECT) {
      return true
    }
  })

  const childrenOptions = children.map(param => {
    const source = buildParamSource(param)

    const includeValue =
      allowedTypeSet.has(param.type) ||
      (param.type.type === dataTypes.OBJECT &&
        (allowedObjectSet.has(param.type.tableId) ||
          allowedTypeSet.has(dataTypes.OBJECT)))

    let children

    if (param.type.type === dataTypes.OBJECT) {
      const datasource = getDatasource(state, appId, param.type.datasourceId)
      const useCollection = usesCollections(datasource)

      const collections = useCollection
        ? datasource.collections
        : datasource.tables

      const collectionId = useCollection
        ? param.type.collectionId
        : param.type.tableId

      const collection = collections[collectionId]

      if (datasource.type === 'api') {
        children = getEndpointFieldSources(source, {
          state,
          datasource,
          collectionId,
          collection,
          allowedDataTypes,
        })
      } else {
        children = getTableFieldOptions(
          state,
          collection,
          source,
          datasource,
          allowedDataTypes,
          allowedObjectTypes,
          allowedListTypes
        )
      }
    }

    const label = param.name

    return {
      label,
      subtitle: 'Parameter',
      value: includeValue ? { ...source, label } : undefined,
      children,
    }
  })

  return childrenOptions
}

// This returns data needed to construct the Logged In User option (both clickable and nonclickable)
const getLoggedInUserForScreen = ({
  state,
  appId,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
}) => {
  const datasources = getDatasourcesObject(state, appId)
  const primaryDatasourceId = Object.keys(datasources)[0]
  const primaryDatasource = datasources[primaryDatasourceId]
  const usersTableId = primaryDatasource.auth && primaryDatasource.auth.table
  const usersTable = primaryDatasource.tables[usersTableId]

  // TODO: Properly get current user?
  if (primaryDatasource.type === 'api' || !primaryDatasource.auth) {
    return []
  }

  return [
    {
      primaryDatasourceId,
      usersTableId,
      allowedDataTypes,
      allowedObjectTypes,
      usersTable,
      primaryDatasource,
      allowedListTypes,
      state,
      appId,
    },
  ]
}

// This returns an empty or single-object array holding a clickable Logged In User option
const getLoggedInUserOption = loggedInUserData =>
  loggedInUserData
    // Check that there is a value to begin with (something to click on)
    .filter(
      ({ allowedDataTypes, allowedObjectTypes, usersTableId }) =>
        allowedDataTypes.includes(dataTypes.OBJECT) ||
        allowedObjectTypes.includes(usersTableId)
    )
    // Return a single-object array holding the logged-in user information
    .map(
      ({ primaryDatasourceId, usersTableId }) =>
        new MenuOption(
          `${AUTHENTICATED} User`,
          buildTableSource({
            datasourceId: primaryDatasourceId,
            tableId: usersTableId,
            selector: {
              type: selectors.CURRENT_USER,
            },
          }),
          'relationship-single'
        )
    )

// This returns a single-object array holding a Logged In User option with submenus
const getLoggedInUserMenu = loggedInUserData =>
  // Return a single-object array holding the logged-in user's children menus
  loggedInUserData.map(
    ({
      primaryDatasourceId,
      usersTableId,
      allowedDataTypes,
      allowedObjectTypes,
      usersTable,
      primaryDatasource,
      allowedListTypes,
      state,
      appId,
    }) => {
      let children = getTableFieldOptions(
        state,
        usersTable,
        buildTableSource({
          datasourceId: primaryDatasourceId,
          tableId: usersTableId,
          selector: {
            type: selectors.CURRENT_USER,
          },
        }),
        primaryDatasource,
        allowedDataTypes,
        allowedObjectTypes,
        allowedListTypes
      )

      const app = getApp(state, appId)

      if (app.externalUsers?.enabled) {
        let authTokenLabel = 'External Users Auth Token'
        let userIdLabel = 'External Users ID'

        const isXanoApp = verifyXanoApp(app)
        const isGsheetApp = verifyGsheetApp(app)

        if (isXanoApp) {
          authTokenLabel = 'Xano Auth Token'
          userIdLabel = 'Xano User ID'
        }

        if (isGsheetApp) {
          authTokenLabel = 'Google Sheet Auth Token'
          userIdLabel = 'Google Sheet User ID'
        }

        children = [
          ...children,
          null, // Dividers
          // Section: External User specific menu items
          new MenuOption(
            authTokenLabel,
            {
              dataType: dataTypes.TEXT,
              datasourceId: primaryDatasourceId,
              type: sourceTypes.EXTERNAL_USERS_TOKEN,
              label: authTokenLabel,
            },
            ''
          ),
          new MenuOption(
            userIdLabel,
            {
              dataType: dataTypes.TEXT,
              datasourceId: primaryDatasourceId,
              type: sourceTypes.EXTERNAL_USERS_ID,
              label: userIdLabel,
            },
            ''
          ),
          // End Section
        ]
      }

      const label = `${AUTHENTICATED} ${possessiveExpansion('User')}`

      return new Submenu(label, [{ label, inline: true, children }])
    }
  )

const getTableFromData = (datasource, table) =>
  table.collectionId
    ? datasource?.collections[table.collectionId]
    : datasource?.tables[table.tableId]

// This returns table/collection data relevant to the current screen
const getContextualTablesForScreen = ({
  state,
  appId,
  componentId,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
}) => {
  const datasources = getDatasourcesObject(state, appId)

  const contextualTables =
    getContextualTables(
      getApp(state, appId),
      componentId,
      getInboundLinks(state, appId)
    ) || []

  return {
    datasources,
    contextualTables,
    state,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
  }
}

// This returns an array of collections which are selectable (clickable) and without submenus
const getContextualTableOptions = ({
  datasources,
  contextualTables,
  allowedDataTypes,
  allowedObjectTypes,
}) =>
  contextualTables
    .filter(
      table =>
        // Filter out tables that would have no value to begin with
        (allowedDataTypes.includes(dataTypes.OBJECT) ||
          allowedObjectTypes.includes(table.tableId)) &&
        // Filter out tables without a backing datasource
        getTableFromData(datasources[table.datasourceId], table)
    )
    // Return an array of child-less menu options with values (clickable)
    .map(table => {
      const relatedTable = getTableFromData(
        datasources[table.datasourceId],
        table
      )

      const tableName = capitalize(
        singularize(relatedTable.name || '[Untitled]')
      )

      const label = `${CURRENT} ${tableName}`

      return new MenuOption(
        label,
        { ...buildRouteParamSource(table), label },
        'relationship-single'
      )
    })

// This returns an array of collections which have submenus but are not clickable
const getContextualTableMenus = ({
  datasources,
  contextualTables,
  allowedDataTypes,
  allowedObjectTypes,
  state,
  allowedListTypes,
}) =>
  contextualTables
    // Filter out tables without a backing datasource
    .filter(table => getTableFromData(datasources[table.datasourceId], table))
    // Return an array of value-less menu options with children (submenus)
    .map(table => {
      const relatedTable = getTableFromData(
        datasources[table.datasourceId],
        table
      )

      const datasource = datasources[table.datasourceId]
      const tableSource = buildRouteParamSource(table)

      const tableName = capitalize(
        singularize(relatedTable.name || '[Untitled]')
      )

      const label = `${CURRENT} ${possessiveExpansion(tableName)}`

      const subMenuWithHeader = {
        label,
        inline: true,
        children:
          datasource.type === 'api'
            ? getEndpointFieldSources(tableSource, {
                state,
                datasource,
                collectionId: table.collectionId,
                collection: datasource.collections[table.collectionId],
                allowedDataTypes,
              })
            : getTableFieldOptions(
                state,
                relatedTable,
                tableSource,
                datasource,
                allowedDataTypes,
                allowedObjectTypes,
                allowedListTypes
              ),
      }

      return new Submenu(label, [subMenuWithHeader])
    })

// Used for forms
export const getTableOptions = (state, appId) => {
  const datasources = getDatasources(state, appId)
  const options = []

  const disableExternalCollections = !isFeatureEnabled(
    state,
    'customIntegrations'
  )

  const disableXanoCollections = !isFeatureEnabled(state, 'externalDatabase')

  for (const datasource of datasources) {
    const datasourceId = datasource.id
    const collections = datasource.tables || datasource.collections
    const key = datasource.tables ? 'tableId' : 'collectionId'

    for (const id of Object.keys(collections)) {
      const collection = collections[id]

      if (disableExternalCollections && collection.type === 'api') continue
      if (disableXanoCollections && collection.type === 'xano') continue

      options.push(
        new MenuOption(
          collection.name,
          { datasourceId, [key]: id },
          'collections'
        )
      )
    }
  }

  return options
}

export const getFormReferenceOptions = (state, appId, objectId, tableInfo) => {
  if (!tableInfo) {
    return []
  }

  const { datasourceId, tableId } = tableInfo
  const datasource = getDatasource(state, appId, datasourceId)
  const collection = getCollection(datasource, tableInfo)
  const component = getComponent(state, objectId)
  const componentId = component.id
  const table = datasource?.tables[tableId] ?? {}

  if (!collection) {
    return []
  }

  const tableName = singularize(collection.name).toLowerCase()

  let result = []

  if (tableId) {
    const { table: authTable } = datasource.auth

    if (authTable === tableId) {
      if (table?.type !== 'xano') {
        // Not users table
        result.push(new MenuOption('Log the User In', 'login', 'lock-open'))
        result.push(new MenuOption('Sign the User Up', 'signup', 'person-add'))
      }
    } else {
      // Users table
      result.push(new MenuOption(`Create New ${tableName}`, 'new', 'plus'))
    }

    const updateOptions = getContextualObjects(
      state,
      appId,
      componentId,
      objectId
    )()
      .filter(opt => opt.value && opt.value.tableId === tableId)
      .map(
        opt =>
          new MenuOption(
            `Update ${opt.label}`,
            opt.value,
            'relationship-single'
          )
      )

    if (updateOptions.length > 0) {
      result.push(null)
      result = result.concat(updateOptions)
    }
  } else {
    // TODO: implement API stuff
  }

  return result
}

// Used for libraries
export const getSimpleTableBindings = (state, appId, helpers) => {
  const datasources = getDatasourcesObject(state, appId)
  const hasExternalCollections = isFeatureEnabled(state, 'customIntegrations')
  const hasExternalDatabase = isFeatureEnabled(state, 'externalDatabase')
  const { handleTrial, hoverContent } = helpers

  let options = []

  for (const datasourceId of Object.keys(datasources)) {
    const datasource = datasources[datasourceId]

    for (const tableId of Object.keys(datasource.tables || {})) {
      const table = datasource.tables[tableId]
      const { name, type } = table

      const disableAPI = type === 'api' && !hasExternalCollections
      const disableXano = type === 'xano' && !hasExternalDatabase
      const shouldDisableOption = disableAPI || disableXano

      const featureName =
        type === 'api' ? 'externalCollection' : 'externalDatabase'

      if (shouldDisableOption) {
        options.push({
          label: name,
          locked: true,
          rightIcon: <Icon type="lock-small" small />,
          onClick: handleTrial(featureName),
          hoverContent: type === 'api' ? hoverContent : 'upgrade',
          icon: 'collections',
        })
      } else {
        const tableSource = buildTableSource({
          datasourceId,
          tableId,
        })

        options.push(new MenuOption(name, tableSource, 'collections'))
      }
    }
  }

  options = options.concat(
    getAPISources({
      state,
      appId,
      allowedDataTypes: [dataTypes.LIST],
    })
  )

  return addBindings(options, [bindingTypes.LIBRARY_PROP])
}

export const getListChildBindings = opts => () => {
  const {
    state,
    appId,
    objectId,
    reference,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    sourcesOnly,
  } = opts

  const object = selectObject(state, objectId)

  const parentListBinding =
    typeof reference === 'string'
      ? object.attributes[reference]
      : typeof reference === 'object'
      ? reference
      : null

  if (!parentListBinding) {
    return []
  }

  const { datasourceId, tableId, collectionId } = parentListBinding.source
  const { id } = parentListBinding

  const datasource = getDatasource(state, appId, datasourceId)

  if (datasource?.type === 'api') {
    const collection = datasource.collections[collectionId]

    const parentSource = buildListItemSource({
      listObjectId: parentListBinding.id,
      datasourceId,
      collectionId,
    })

    const sources = getEndpointFieldSources(parentSource, {
      state,
      collection,
      allowedDataTypes,
    })

    if (
      allowedDataTypes.includes(dataTypes.OBJECT) ||
      (allowedObjectTypes && allowedObjectTypes.includes(collectionId))
    ) {
      sources.push(
        new MenuOption(`${singularize(collection.name)} item`, parentSource)
      )
    }

    return sourcesOnly
      ? sources
      : addBindings(sources, [bindingTypes.LIBRARY_PROP])
  }

  const table = getTable(state, appId, datasourceId, tableId)

  if (!table) return []

  const source = buildListItemSource({
    datasourceId,
    tableId,
    listObjectId: id,
  })

  const tableName = capitalize(singularize(table.name || ''))
  const label = `Current ${tableName}`

  const sources = []

  if (
    allowedDataTypes.includes(dataTypes.OBJECT) ||
    (allowedObjectTypes && allowedObjectTypes.includes(tableId))
  ) {
    sources.push(new MenuOption(label, source))
  }

  const fieldSources = getTableFieldOptions(
    state,
    table,
    source,
    datasource,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes
  )

  if (fieldSources.length > 0) {
    sources.push({
      label,
      children: fieldSources,
      inline: true,
    })
  }

  const result = sourcesOnly
    ? sources
    : addBindings(sources, [bindingTypes.LIBRARY_PROP])

  return result
}

// Returns a list of collection menu items with submenus
const getTableBindings = ({
  state,
  appId,
  allowedDataTypes,
  allowedListTypes,
}) => {
  const datasources = getDatasourcesObject(state, appId)
  const options = []

  for (const datasourceId of Object.keys(datasources)) {
    const { tables = {} } = datasources[datasourceId] || {}

    for (const tableId of Object.keys(tables)) {
      const table = tables[tableId]

      const tableSource = buildTableSource({
        datasourceId,
        tableId,
      })

      const children = getCollectionBindings({
        table,
        allowedDataTypes,
        allowedListTypes,
        source: tableSource,
        allowedSourceTypes: aggregateSourceTypes,
      })

      if (children.length) {
        options.push({
          label: table.name || '[Untitled]',
          children,
        })
      }
    }
  }

  return options
}

const getObjectDataTypes = (
  table,
  tableSource,
  datasource,
  allowedDataTypes,
  state
) => {
  const result = []

  const orderedFields = getOrderedFields(table)

  const externalCollectionTypes = ['api', 'xano']

  const isNotExternalCollection =
    table && !externalCollectionTypes.includes(table.type)

  if (isNotExternalCollection) {
    orderedFields.push('id')
  }

  if (hasTimestamps(datasource, isNotExternalCollection)) {
    orderedFields.push('created_at', 'updated_at')
  }

  for (const fieldId of orderedFields) {
    const field = getTableField(table, fieldId)

    const source = buildFieldSource({
      fieldId,
      dataType: field.type,
      source: tableSource,
    })

    switch (field.type) {
      case dataTypes.TEXT:
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
      case dataTypes.BOOLEAN:
        if (allowedDataTypes.includes(field.type)) {
          result.push(
            new MenuOption(field.name, source, getFieldIcon(field.type))
          )
        }

        break
      case dataTypes.IMAGE:
        // Make the image item clickable (give it a value) if the context allows for it
        if (allowedDataTypes.includes(dataTypes.IMAGE)) {
          result.push(
            new MenuOption(field.name, source, getFieldIcon(dataTypes.IMAGE))
          )
          // Otherwise, give the menu item a submenu if the context allows for it
        } else if (allowedDataTypes.includes(dataTypes.TEXT)) {
          result.push(
            new Submenu(
              field.name,
              getObjectFieldOptions(source, allowedDataTypes, IMAGE_OPTIONS),
              true
            )
          )
        }

        break
      case dataTypes.FILE:
        // Make the file item clickable (give it a value) if the context allows for it
        if (allowedDataTypes.includes(dataTypes.FILE)) {
          result.push(
            new MenuOption(field.name, source, getFieldIcon(dataTypes.FILE))
          )
          // Otherwise, give the file item a submenu if the context allows for it
        } else if (
          allowedDataTypes.includes(dataTypes.TEXT) ||
          allowedDataTypes.includes(dataTypes.NUMBER)
        ) {
          result.push(
            new Submenu(
              field.name,
              getObjectFieldOptions(source, allowedDataTypes, FILE_OPTIONS),
              true
            )
          )
        }

        break

      case dataTypes.LOCATION:
        // The location item as a selectable option
        if (allowedDataTypes.includes(dataTypes.LOCATION)) {
          result.push(
            new MenuOption(field.name, source, getFieldIcon(dataTypes.LOCATION))
          )
        }

        // The location item as an expandable menu with location sub-properties
        if (
          allowedDataTypes.includes(dataTypes.TEXT) ||
          allowedDataTypes.includes(dataTypes.NUMBER)
        ) {
          result.push(
            new Submenu(
              field.name,
              getObjectFieldOptions(source, allowedDataTypes, LOCATION_OPTIONS),
              true
            )
          )
        }

        break
    }
  }

  return result
}

const getToManyFieldMenus = (
  tableSource,
  datasource,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
  allowedSourceTypes,
  state
) => {
  const hasManyRelations = getHasManyRelations(datasource, tableSource.tableId)

  return hasManyRelations.map(({ fieldId, tableId, name }) => {
    const newTableSource = buildHasManySource({
      fieldId,
      tableId,
      source: tableSource,
    })

    const table = datasource.tables[tableId]

    const label = possessiveExpansion(name)

    const children = () => {
      let children = getCollectionBindings({
        table,
        allowedDataTypes,
        allowedListTypes,
        source: newTableSource,
        allowedSourceTypes,
      })

      const childRelationOptions = getTableFieldOptions(
        state,
        datasource.tables[tableId],
        newTableSource,
        datasource,
        allowedDataTypes,
        allowedObjectTypes,
        allowedListTypes,
        allowedSourceTypes
      )

      if (childRelationOptions.length > 0) {
        if (children.length > 0) {
          children.push(null)
        }

        children = children.concat(childRelationOptions)
      }

      return [{ label, inline: true, children }]
    }

    return new Submenu(possessiveExpansion(name), children)
  })
}

const getManyToManyFieldMenus = (
  tableSource,
  datasource,
  allowedDataTypes,
  allowedObjectTypes,
  allowedListTypes,
  allowedSourceTypes,
  state
) => {
  const manyToManyRelations = getManyToManyRelations(
    datasource,
    tableSource.tableId
  )

  return manyToManyRelations.map(({ fieldId, tableId, name }) => {
    const newTableSource = buildManyToManySource({
      fieldId,
      tableId,
      source: tableSource,
    })

    const table = datasource.tables[tableId]

    const children = () => {
      let childOptions = getCollectionBindings({
        table,
        allowedDataTypes,
        allowedListTypes,
        source: newTableSource,
        allowedSourceTypes,
      })

      const childRelationOptions = getTableFieldOptions(
        state,
        datasource.tables[tableId],
        newTableSource,
        datasource,
        allowedDataTypes,
        allowedObjectTypes,
        allowedListTypes,
        limitSourceTypes
      )

      if (childRelationOptions.length > 0) {
        if (childOptions.length > 0) {
          childOptions.push(null)
        }

        childOptions = childOptions.concat(childRelationOptions)
      }

      return childOptions
    }

    return new Submenu(capitalize(name), children)
  })
}

const getBelongsToFieldOptions =
  belongsToFieldIds =>
  (table, tableSource, _, allowedDataTypes, allowedObjectTypes) =>
    belongsToFieldIds
      .filter(fieldId => {
        const field = table.fields[fieldId]

        const isNotList = tableSource.dataType !== dataTypes.LIST

        const includeObjectValue =
          allowedObjectTypes.includes(field.type.tableId) ||
          allowedDataTypes.includes(dataTypes.OBJECT)

        return isNotList && includeObjectValue
      })
      .map(fieldId => {
        const field = table.fields[fieldId]
        const isList = tableSource.dataType === dataTypes.LIST

        const fieldName = field.name || 'Untitled'
        const label = isList ? pluralize(fieldName) : fieldName

        return new MenuOption(
          label,
          buildBelongsToSource({
            fieldId,
            multi: isList,
            source: tableSource,
            tableId: field.type.tableId,
          }),
          'relationship-single'
        )
      })

const getBelongsToFieldMenus =
  belongsToFieldIds =>
  (
    table,
    tableSource,
    datasource,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    allowedSourceTypes,
    state
  ) =>
    belongsToFieldIds.map(fieldId => {
      const field = table.fields[fieldId]
      const multi = tableSource.dataType === dataTypes.LIST

      const belongsToSource = buildBelongsToSource({
        fieldId,
        multi,
        source: tableSource,
        tableId: field.type.tableId,
      })

      const fieldName = field.name || 'Untitled'

      const label = multi
        ? possessiveExpansion(pluralize(fieldName))
        : possessiveExpansion(fieldName)

      const fieldTypeTable = datasource.tables[field.type.tableId]

      const children = () => {
        let children = []

        if (multi) {
          children = children.concat(
            getCollectionBindings({
              table: fieldTypeTable,
              allowedDataTypes,
              allowedListTypes,
              source: belongsToSource,
              allowedSourceTypes,
            })
          )
        }

        const newOptions = getTableFieldOptions(
          state,
          datasource.tables[field.type.tableId],
          belongsToSource,
          datasource,
          allowedDataTypes,
          allowedObjectTypes,
          allowedListTypes,
          allowedSourceTypes
        )

        if (children.length && newOptions.length) {
          children.push(null)
        }

        children = children.concat(newOptions)

        return [{ label, inline: true, children }]
      }

      return new Submenu(label, children)
    })

// Returns the binding options for individual fields of a table
const getTableFieldOptions = (
  state,
  table,
  tableSource,
  datasource,
  allowedDataTypes,
  allowedObjectTypes = [],
  allowedListTypes = [],
  allowedSourceTypes = aggregateSourceTypes
) => {
  let options = []

  if (!table) {
    return options
  }

  // OBJECT DATA TYPES
  if (tableSource.dataType === dataTypes.OBJECT) {
    options = getObjectDataTypes(
      table,
      tableSource,
      datasource,
      allowedDataTypes,
      state
    )
  }

  // RELATIONSHIP DATA TYPES
  const fields = table.fields

  const belongsToFields = Object.keys(fields).filter(
    id => fields[id].type.type === 'belongsTo'
  )

  // BelongsTo Options (clickable, without children menus)
  const belongsToFieldOptions = getBelongsToFieldOptions(belongsToFields)(
    table,
    tableSource,
    datasource,
    allowedDataTypes,
    allowedObjectTypes
  )

  // Append clickable menu options (with values, without children)
  options = [...options, ...belongsToFieldOptions]

  // Get inbound has-many connections
  if (usesCollections(datasource)) {
    return options
  }

  // BelongsTo Menus (expandable, not clickable)
  const belongsToFieldMenus = getBelongsToFieldMenus(belongsToFields)(
    table,
    tableSource,
    datasource,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    allowedSourceTypes,
    state
  )

  // HasMany Menus (expandable, not clickable)
  const hasManyFieldMenus = getToManyFieldMenus(
    tableSource,
    datasource,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    allowedSourceTypes,
    state
  )

  // ManyToMany Menus (expandable, not clickable)
  const manyToManyFieldMenus = getManyToManyFieldMenus(
    tableSource,
    datasource,
    allowedDataTypes,
    allowedObjectTypes,
    allowedListTypes,
    allowedSourceTypes,
    state
  )

  const insertDivider =
    options.length &&
    (belongsToFieldMenus.length ||
      hasManyFieldMenus.length ||
      manyToManyFieldMenus.length)

  // Append expandable menu items (without values, with children)
  options = [
    ...options,
    ...(insertDivider ? [null] : []),
    ...belongsToFieldMenus,
    ...hasManyFieldMenus,
    ...manyToManyFieldMenus,
  ]

  const hiddenFields = [
    protonFields.TEMPORARY_PASSWORD,
    protonFields.TEMPORARY_PASSWORD_EXPIRES_AT,
  ]

  return options.filter(
    option =>
      !(option && option.value && hiddenFields.includes(option.value.fieldId))
  )
}

const getCollectionBindings = opts => {
  const {
    source,
    allowedDataTypes,
    allowedListTypes,
    table,
    allowedSourceTypes,
  } = opts

  let result = []

  if (
    allowedDataTypes.includes(dataTypes.LIST) ||
    allowedListTypes.includes(source.tableId)
  ) {
    // Add 'All'
    result.push(
      new MenuOption('All', source, getFieldIcon({ type: 'hasManyRelation' }))
    )
  }

  // Add 'Count'
  if (allowedDataTypes.indexOf(dataTypes.NUMBER) !== -1) {
    const countSource = buildCountSource({ source })

    result.push(
      new MenuOption('Count', countSource, getFieldIcon(dataTypes.NUMBER))
    )

    const numberFields = Object.keys(table.fields).filter(
      id => table.fields[id].type === dataTypes.NUMBER
    )

    const fieldOptions = numberFields.map(fieldId => ({
      label: table.fields[fieldId].name,
      children: allowedSourceTypes.map(
        aggregator =>
          new MenuOption(
            sourceTypeNames[aggregator],
            buildAggregateSource({
              fieldId,
              source,
              type: aggregator,
            }),
            'filter-7'
          )
      ),
    }))

    if (fieldOptions.length > 0) {
      result.push(null)
      result = result.concat(fieldOptions)
    }
  }

  return result
}

const getObjectFieldOptions = (source, allowedDataTypes, options) => {
  if (!options) {
    return []
  }

  return allowedDataTypes
    .filter(allowedDataType => Object.keys(options).includes(allowedDataType))
    .flatMap(dataType =>
      options[dataType].map(({ label, fieldId }) => {
        const fieldSourceFunction =
          fieldId === 'url' ? buildURLFieldSource : buildFieldSource

        return new MenuOption(
          label,
          fieldSourceFunction({ fieldId, dataType, source }),
          getFieldIcon(dataType)
        )
      })
    )
}

// Used by delete / update actions to get list of objects
// Also used by belongs to fields for create / update
export const getContextualObjects =
  (
    state,
    appId,
    componentId,
    objectId,
    skipSelects = false,
    actionId = null,
    reference,
    opts = {}
  ) =>
  () => {
    const objects = selectObjects(state)
    const parents = []
    const path = getPath(state, objectId)
    const datasources = getDatasourcesObject(state, appId)
    let options = []

    if (reference) {
      options = getListChildBindings({
        state,
        appId,
        objectId,
        reference,
        allowedDataTypes: [dataTypes.OBJECT],
        sourcesOnly: true,
      })()
    }

    for (let i = 1; i <= pathLength(path); i += 1) {
      const parentPath = subPath(path, i)
      parents.push(getObject(objects, parentPath))
    }

    parents.forEach(parentObj => {
      const binding = parentObj.dataBinding

      if (binding && binding.bindingType === bindingTypes.LIST) {
        const sourceObject = buildSourceObject(binding.source)
        const datasourceId = sourceObject.getDatasourceId()
        const tableId = sourceObject.getTableId()

        const datasource = datasources[datasourceId]

        if (!datasource) return

        const table = datasource.tables[tableId]

        const source = buildListItemSource({
          datasourceId,
          tableId,
          listObjectId: parentObj.id,
        })

        if (table) {
          const label = `${CURRENT} ${singularize(table.name)}`

          options.push({
            label,
            subtitle: 'List Item',
            value: { ...source, label },
            children: getRelatedObjects(datasource, tableId, source, label),
          })
        }
      }
    })

    // Get route-param contextual tables
    const inboundLinks = getInboundLinks(state, appId)
    const app = getApp(state, appId)

    const contextualTables =
      getContextualTables(app, componentId, inboundLinks) || []

    contextualTables.forEach(obj => {
      const { datasourceId, tableId } = obj
      const datasource = datasources[datasourceId]
      const table = datasource.tables[tableId]

      const source = buildRouteParamSource({
        datasourceId,
        tableId,
      })

      if (table) {
        const label = `${CURRENT} ${singularize(table.name)}`

        options.push({
          label,
          value: { ...source, label },
          children: getRelatedObjects(datasource, tableId, source, label),
        })
      }
    })

    const primaryDatasourceId = Object.keys(datasources)[0]
    const primaryDatasource = datasources[primaryDatasourceId]

    // Current user

    if (primaryDatasource && primaryDatasource.auth) {
      const usersTableId =
        primaryDatasource.auth && primaryDatasource.auth.table

      const source = buildTableSource({
        datasourceId: primaryDatasourceId,
        tableId: usersTableId,
        selector: {
          type: selectors.CURRENT_USER,
        },
      })

      const label = `${AUTHENTICATED} User`

      options.push({
        label,
        value: source,
        children: getRelatedObjects(
          primaryDatasource,
          usersTableId,
          source,
          label
        ),
      })
    }

    // Select menus
    if (!skipSelects) {
      const selects = getSelects(state, componentId)

      for (const id of selects) {
        const select = selectObject(state, id)

        if (!select) {
          continue
        }

        const binding = select.dataBinding

        if (
          binding &&
          binding.source &&
          binding.bindingType === bindingTypes.LIST
        ) {
          const source = buildSelectSource({
            datasourceId: binding.source.datasourceId,
            tableId: binding.source.tableId,
            selectObjectId: select.id,
          })

          const label = `${getObjectName(select)}` // → value`

          options.push(new MenuOption(label, { ...source, label }))
        }
      }
    }

    // Create object
    const allowedDataTypes = [dataTypes.OBJECT]

    const args = {
      state,
      appId,
      componentId,
      objectId,
      actionId,
      allowedDataTypes,
      ...opts,
    }

    const createdObjectOptions = getCreatedObjectItems(
      args,
      getCreatedObjectOption
    )

    const createdObjectFormOption = getCreatedObjectFormOption(args)

    const createdObjectMenus = getCreatedObjectItems(args, getCreatedObjectMenu)

    const createdObjectFormMenu = getCreatedObjectFormMenu(args)

    const insertDivider =
      (options.length ||
        createdObjectOptions.length ||
        createdObjectFormOption.length) &&
      (createdObjectMenus.length || createdObjectFormMenu.length)

    options = [
      ...options,
      ...createdObjectOptions,
      ...createdObjectFormOption,
      ...(insertDivider ? [null] : []),
      ...createdObjectMenus,
      ...createdObjectFormMenu,
    ]

    // Params
    const params = getParams(state, appId, componentId).filter(
      p => p.type && p.type.type === dataTypes.OBJECT
    )

    params.forEach(param => {
      options.push({
        label: param.name,
        subtitle: 'Parameter',
        value: buildParamSource(param),
      })
    })

    return options
  }

const getRelatedObjects = (datasource, tableId, source, baseLabel) => {
  // Get belongsTo relationships
  const relations = getBelongsToRelations(datasource.tables[tableId])

  if (relations.length === 0) {
    return
  }

  return relations.map(relation => {
    const newSource = buildBelongsToSource({
      source,
      fieldId: relation.fieldId,
      tableId: relation.tableId,
    })

    const label = `${baseLabel} > ${relation.name}`

    return {
      label: relation.name,
      value: { ...newSource, label },
      children: () =>
        getRelatedObjects(datasource, relation.tableId, newSource, label),
    }
  })
}

export const getInputObjectsSub = (state, componentId) => {
  const inputs = getInputs(state, componentId)

  return inputs.map(inputId => {
    if (inputId && typeof inputId === 'object') {
      const { objectId, prop } = inputId
      const object = selectObject(state, objectId)
      const joinedId = `${objectId}.${prop.join('.')}`
      const app = getApp(state, getCurrentAppId(state))

      return new MenuOption(
        getLibraryPropLabel(app, object, prop),
        joinedId,
        'text-format'
      )
    }

    return new MenuOption(
      getObjectName(selectObject(state, inputId)),
      inputId,
      'text-format'
    )
  })
}

export const getInputObjects = (state, appId, componentId) => {
  const screens = getScreens(state, appId)

  const allScreens = screens.filter(s => s.id !== componentId)

  const options = getInputObjectsSub(state, componentId)

  if (options.length > 0) {
    options.push(null)
  }

  options.push(
    new Submenu('All Screens', () =>
      allScreens
        .map(component => ({
          label: getObjectName(component),
          children: () => getInputObjectsSub(state, component.id),
        }))
        .filter(component => component.children().length > 0)
    )
  )

  return options
}

// Used by libraries for automatic source assignment
export const getPrimaryFieldSource = (state, appId, listBinding) => {
  if (!listBinding) {
    return null
  }

  const { source } = listBinding
  const { datasourceId, tableId } = source

  const fields = getSourceTableFields(state, appId, source)

  let primaryFieldId = null
  let primaryField = null

  for (const fieldId of Object.keys(fields)) {
    if (fields[fieldId].isPrimaryField) {
      primaryField = fields[fieldId]
      primaryFieldId = fieldId

      break
    }
  }

  if (!primaryField || primaryField.type !== dataTypes.TEXT) {
    return null
  }

  const itemSource = buildListItemSource({
    datasourceId,
    tableId,
    listObjectId: listBinding.id,
  })

  const fieldSource = buildFieldSource({
    fieldId: primaryFieldId,
    dataType: primaryField.type,
    source: itemSource,
  })

  const binding = buildBinding(bindingTypes.LIBRARY_PROP, fieldSource)

  return binding
}

export const getImageFieldSource = (state, appId, listBinding) => {
  if (!listBinding) {
    return null
  }

  const { source } = listBinding
  const { datasourceId, tableId } = source

  const fields = getSourceTableFields(state, appId, source)

  let imageFieldId = null
  let imageField = null

  for (const fieldId of Object.keys(fields)) {
    if (fields[fieldId].type === dataTypes.IMAGE) {
      imageField = fields[fieldId]
      imageFieldId = fieldId

      break
    }
  }

  if (!imageField) {
    return null
  }

  const itemSource = buildListItemSource({
    datasourceId,
    tableId,
    listObjectId: listBinding.id,
  })

  const fieldSource = buildFieldSource({
    fieldId: imageFieldId,
    dataType: imageField.type,
    source: itemSource,
  })

  return buildBinding(bindingTypes.LIBRARY_PROP, fieldSource)
}

const getSourceTableFields = (state, appId, source) => {
  const { datasourceId, tableId } = source

  // Catch API case
  if (!datasourceId || !tableId) {
    return {}
  }

  const table = getTable(state, appId, datasourceId, tableId)
  const fields = table.fields

  return fields
}

/////////////////////////////////////////////
// API Sources
/////////////////////////////////////////////

export const getAPISources = opts => {
  const { state, appId, allowedDataTypes } = opts

  const datasources = getDatasources(state, appId).filter(
    source => source.type === 'api'
  )

  const options = []

  datasources.forEach(datasource => {
    const { collections } = datasource

    Object.keys(collections).forEach(collectionId => {
      const collection = collections[collectionId]

      options.push({
        label: collection.name,
        subtitle: `/${collection.baseURL}`,
        children: getEndpointSources({
          state,
          datasource,
          collectionId,
          collection,
          allowedDataTypes,
        }),
      })
    })
  })

  return options
}

export const getEndpointSources = opts => {
  const { state, datasource, collectionId, collection, allowedDataTypes } = opts

  const options = []
  const endpoints = getDataEndpoints(collection)

  endpoints.forEach(endpoint => {
    const label = getEndpointLabel(endpoint)
    const subtitle = getEndpointSubtitle(collection, endpoint)

    const children = () =>
      getEndpointChildSources({
        state,
        datasource,
        collectionId,
        collection,
        endpoint,
        allowedDataTypes,
      })

    options.push({
      label,
      subtitle,
      children,
    })
  })

  return options
}

export const getEndpointChildSources = opts => {
  const { datasource, collectionId, endpoint, allowedDataTypes } = opts

  const isList = isListEndpoint(endpoint)

  const parentSource = buildAPIEndpointSource({
    datasourceId: datasource.id,
    collectionId,
    endpointId: endpoint.id,
    endpoint,
  })

  if (isList) {
    if (!allowedDataTypes.includes(dataTypes.LIST)) {
      return []
    }

    return getEndpointListSources(parentSource)
  }

  return getEndpointFieldSources(parentSource, opts)
}

const getEndpointListSources = source => {
  return [
    {
      label: 'All',
      subtitle: 'List',
      value: source,
    },
  ]
}

const getEndpointFieldSources = (source, opts) => {
  const { collection, allowedDataTypes } = opts

  const { fields } = collection

  return getEndpointFieldSourcesSub(source, fields, allowedDataTypes)
}

export const getEndpointFieldSourcesSub = (
  parentSource,
  fields,
  allowedDataTypes
) => {
  const options = []
  fields = fields || []

  fields.forEach(field => {
    const source = buildAPIFieldSource({
      source: parentSource,
      fieldId: field.id,
      dataType: field.type,
    })

    let children

    if (field.type === dataTypes.OBJECT) {
      children = getEndpointFieldSourcesSub(
        source,
        field.children,
        allowedDataTypes
      )
    }

    if (field.type === dataTypes.LIST) {
      children = getEndpointListSources(source)
    }

    let value

    if (allowedDataTypes.includes(field.type)) {
      value = source
    }

    if (allowedDataTypes.includes(field.type) || children) {
      options.push({
        label: field.id,
        value,
        children,
      })
    }
  })

  return options
}

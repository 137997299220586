import type { AppExternalUsers } from 'components/Editor/XanoExternalDatabaseModal/lib/user' // TODO: move to a shared location

export const verifyXanoApp = (app: AppExternalUsers): boolean => {
  return app?.externalUsers?.enabled && app?.externalUsers?.provider === 'xano'
}
export const verifyGsheetApp = (app: AppExternalUsers): boolean => {
  return (
    app?.externalUsers?.enabled && app?.externalUsers?.provider === 'gsheet'
  )
}

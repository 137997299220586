// TODO(dyego): convert it to TypeScript
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import { useDispatch, useSelector, connect } from 'react-redux'

import { updateApp } from 'ducks/apps'
import { createCustomAction, fetchCustomActions } from 'ducks/customActions'

import classNames from 'classnames'
import { Field } from 'redux-form'

import { GroupedAccordion } from 'components/Shared/Accordion'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'
import Loading from 'components/Shared/Loading'

import { updateTable, getDatasources } from 'ducks/apps/datasources'

import { XanoContextProvider } from './context'
import { validateXanoBaseUrl, resetBaseUrl } from './context/actions/baseUrl'

import { FORM_NAME } from './index'

import {
  validateAuthSignup,
  validateAuthLogin,
  validateAuthMe,
  resetAuth,
} from './context/actions/auth'

import {
  updateAuthSignupEndpointValidation,
  updateAuthLoginEndpointValidation,
  updateAuthMeEndpointValidation,
} from './lib/auth.tsx'
import { validateUser, resetUser } from './context/actions/user'
import {
  validateUserEndpoints,
  saveLoginCustomAction,
  saveSignupCustomAction,
  updateUsersDatasources,
} from './lib/user.tsx'

const SUCCESS = 'success'
const FAILURE = 'failure'
const LOADING = 'loading'

const USER_SETUP_COMPLETED = 'userSetupCompleted'
const NONE = 'none'

const userSchema = 'User'

const USER_ENDPOINTS = {
  [userSchema]: [
    {
      entity: userSchema,
      label: 'Get All',
      type: 'get',
      path: `/${userSchema.toLowerCase()}`,
      isValid: true,
    },
    {
      entity: userSchema,
      label: 'Create',
      type: 'post',
      path: `/${userSchema.toLowerCase()}`,
      isValid: true,
    },
    {
      entity: userSchema,
      label: 'Delete',
      type: 'delete',
      path: `/${userSchema.toLowerCase()}/{id}`,
      isValid: true,
    },
    {
      entity: userSchema,
      label: 'Get One',
      type: 'get',
      path: `/${userSchema.toLowerCase()}/{id}`,
      isValid: true,
    },
    {
      entity: userSchema,
      label: 'Update',
      type: 'post',
      path: `/${userSchema.toLowerCase()}/{id}`,
      isValid: true,
    },
  ],
}

const ExternalUsersForm = props => {
  const {
    app,
    setNextEnabled,
    createCustomAction,
    fetchCustomActions,
    updateApp,
    setupState,
    setSetupState,
  } = props

  const inputRef = useRef(null)
  const [url, setUrl] = useState('')

  const [baseUrlLoading, setBaseUrlIsLoading] = useState(false)
  const [authLoading, setAuthIsLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)

  const [urlStatus, setUrlStatus] = useState(NONE)
  const [authEndpointsSuccess, setAuthEndpointsSuccess] = useState(NONE)
  const [userEndpointsSuccess, setUserEndpointsSuccess] = useState(NONE)
  const [endpointsValidation, setEndpointsValidation] = useState({
    Authentication: [],
  })

  useEffect(() => {
    if (baseUrlLoading || authLoading || userLoading) {
      setNextEnabled(false)
    } else {
      setNextEnabled(true)
    }
  }, [baseUrlLoading, authLoading, userLoading])

  useEffect(() => {
    if (setupState === LOADING && inputRef.current?.value) {
      // Resets to initial state
      resetBaseUrl()(baseURLDispatch)
      resetAuth()(authDispatch)
      resetUser()(userDispatch)
      setUrlStatus(NONE)
      setAuthEndpointsSuccess(NONE)
      setUserEndpointsSuccess(NONE)
      setEndpointsValidation({
        Authentication: [],
      })

      validateUrl(inputRef.current?.value)
    }
  }, [setupState])

  const dispatch = useDispatch()

  const { appId } = useParams()

  const {
    baseURLState,
    baseURLDispatch,
    authState,
    authDispatch,
    userState,
    userDispatch,
  } = useContext(XanoContextProvider)

  const datasources = useSelector(state => getDatasources(state, appId))

  const datasource = datasources[0]

  const suffix = useMemo(() => {
    if (baseUrlLoading) {
      return <Loading small color="teal" />
    }

    if (urlStatus === SUCCESS) {
      return <Icon type="check-circle" color="teal" />
    } else if (urlStatus === FAILURE) {
      return <Icon className="flipped" type="info" color="orange" />
    }
  }, [baseUrlLoading])

  const renderEndpoints = useMemo(() => {
    const accordions = Object.entries(endpointsValidation).map(
      ([title, endpoints]) => {
        const loading = title === 'Authentication' ? authLoading : userLoading
        const successEndpoints =
          title === 'Authentication'
            ? authEndpointsSuccess === SUCCESS
            : userEndpointsSuccess === SUCCESS

        return (
          <GroupedAccordion
            key={title}
            group={`external-users-endpoints-${title}`}
            className={classNames({
              active: urlStatus === SUCCESS,
            })}
            title={
              <>
                <div>
                  <h3>{title}</h3>
                  {loading ? <Loading small color="white" /> : null}
                  {successEndpoints ? (
                    <Icon type="check-circle" color="teal" />
                  ) : null}
                  {!successEndpoints && loading === false ? (
                    <Icon className="flipped" type="info" color="orange" />
                  ) : null}
                </div>
              </>
            }
            renderChildren={() => (
              <div className="endpoints-container">
                {endpoints.map(endpoint => {
                  const endpointValidation = endpointsValidation[title]?.find(
                    result => result.path === endpoint.path
                  )

                  if (endpointValidation) {
                    return (
                      <EndpointTestContent
                        key={endpoint.label}
                        isValid={endpointValidation.isValid}
                        url={url}
                        {...endpoint}
                      />
                    )
                  }
                })}
              </div>
            )}
          />
        )
      }
    )

    return accordions
  }, [
    urlStatus,
    authEndpointsSuccess,
    authLoading,
    userLoading,
    endpointsValidation,
  ])

  const isValidUrl = inputValue => {
    let isValidUrl

    try {
      const url = new URL(inputValue)
      setUrl(url.href)
      isValidUrl = true
    } catch (error) {
      isValidUrl = false
    }

    return isValidUrl
  }

  const validateUrl = async inputValue => {
    const isValid = isValidUrl(inputValue)

    if (isValid) {
      setBaseUrlIsLoading(true)
      validateXanoBaseUrl(inputValue)(baseURLDispatch)
      setUrl(inputValue)
    }
  }

  const handleBaseUrlChange = e => {
    if (isValidUrl(e.target.value)) {
      setNextEnabled(true)
    } else {
      setNextEnabled(false)
    }
  }

  useEffect(() => {
    if (baseURLState.externalDatabases?.xano) {
      const isValidUrl = baseURLState.externalDatabases?.xano?.validUrl

      if (isValidUrl) {
        setNextEnabled(true)
        setUrlStatus(SUCCESS)

        // dispatch validation for every endpoint
        setAuthIsLoading(true)
        validateAuthSignup(url)(authDispatch)
        validateAuthLogin(url)(authDispatch)
        validateAuthMe(url)(authDispatch)
      } else {
        setNextEnabled(false)
        setUrlStatus(FAILURE)
      }

      setBaseUrlIsLoading(false)
    }
  }, [baseURLState])

  useEffect(() => {
    const authData = authState.externalDatabases?.xano?.auth

    if (authData?.signup) {
      updateAuthSignupEndpointValidation(authState, endpointsValidation)

      setEndpointsValidation({
        Authentication: endpointsValidation.Authentication,
      })
    }

    if (authData?.login) {
      updateAuthLoginEndpointValidation(authState, endpointsValidation)
    }

    if (authData?.me) {
      updateAuthMeEndpointValidation(authState, endpointsValidation)
    }

    if (authData?.signup && authData?.login && authData?.me) {
      setAuthIsLoading(false)

      const endpointsSucceeded = endpointsValidation.Authentication.every(
        endpoint => endpoint.isValid
      )

      if (endpointsSucceeded) {
        setAuthEndpointsSuccess(SUCCESS)
        setUserLoading(true)
        setEndpointsValidation({
          ...endpointsValidation,
          ...USER_ENDPOINTS,
        })
        validateUser(url)(userDispatch)
      } else {
        setUserLoading(false)
        setSetupState(FAILURE)
      }
    }
  }, [authState])

  useEffect(() => {
    const userData = userState.externalDatabases?.xano?.user

    if (userData.message) {
      const invalidUserEndpoints = USER_ENDPOINTS.User.map(endpoint => ({
        ...endpoint,
        isValid: false,
        error: {
          message: userData.message,
        },
      }))

      setEndpointsValidation({
        Authentication: endpointsValidation.Authentication,
        User: invalidUserEndpoints,
      })

      setUserLoading(false)
      setSetupState(FAILURE)
    } else if (userData && Object.keys(userData).length > 0) {
      const { endpointsAreValid, userEndpoints, capitalizedSchema } =
        validateUserEndpoints(userData)

      setUserEndpointsSuccess(endpointsAreValid ? SUCCESS : FAILURE)

      setEndpointsValidation({
        Authentication: endpointsValidation.Authentication,
        [capitalizedSchema]: userEndpoints,
      })
    }

    setUserLoading(false)
  }, [userState])

  const saveCustomActions = async (signupData, loginData) => {
    const {
      customActionId: loginCustomActionId,
      customAction: loginCustomAction,
    } = await saveLoginCustomAction(
      appId,
      app,
      loginData,
      createCustomAction,
      url
    )

    Object.assign(app.externalUsers, {
      login: {
        formValues: loginCustomAction,
        customActionId: loginCustomActionId,
        authToken: loginCustomAction.authTokenField,
      },
    })

    const {
      customActionId: signupCustomActionId,
      customAction: signupCustomAction,
    } = await saveSignupCustomAction(
      appId,
      app,
      signupData,
      createCustomAction,
      url
    )

    Object.assign(app.externalUsers, {
      signup: {
        formValues: signupCustomAction,
        customActionId: signupCustomActionId,
        authToken: signupCustomAction.authTokenField,
      },
    })

    if (url) {
      Object.assign(app.externalUsers, {
        formValues: {
          apiURL: url,
        },
      })
    }

    dispatch(updateApp(appId, app))

    fetchCustomActions(appId)
  }

  useEffect(() => {
    if (
      urlStatus === FAILURE ||
      authEndpointsSuccess === FAILURE ||
      userEndpointsSuccess === FAILURE
    ) {
      setSetupState(FAILURE)
    } else if (userEndpointsSuccess === SUCCESS) {
      setSetupState(USER_SETUP_COMPLETED)
    }
  }, [urlStatus, userEndpointsSuccess, authEndpointsSuccess])

  // NOTE: Here, customActions are saved for login/signup (login/signup endpoints attached to the application, eg: http://googlesheet.com/<some-user's-sheetId>/login or /signup)
  useEffect(() => {
    if (setupState === SUCCESS) {
      const signupData = authState.externalDatabases?.xano?.auth?.signup
      const loginData = authState.externalDatabases?.xano?.auth?.login
      const userData = userState.externalDatabases?.xano?.user

      if (signupData && loginData) {
        saveCustomActions(signupData, loginData) // here

        if (datasource && userData) {
          const { datasourceId, tableId, updatedUserTable } =
            updateUsersDatasources(datasource, userData, url)

          dispatch(updateTable(appId, datasourceId, tableId, updatedUserTable))

          app.externalUsers.setupStatus = {
            login: true,
            signup: true,
            complete: true,
            collection: false,
          }

          dispatch(updateApp(appId, app))
        }
      }
    }
  }, [setupState])

  return (
    <>
      <div className="api-url-input">
        <Field
          id="xano-url-input"
          ref={inputRef}
          onChange={handleBaseUrlChange}
          name="apiURL"
          label="Xano API Base URL"
          component={WrappedInput}
          spellCheck="false"
          formName={FORM_NAME}
          suffix={suffix}
          className={classNames({
            'wrapped-input-error': urlStatus === FAILURE,
          })}
        />
      </div>
      <div name="endpoints" className="external-users-endpoints">
        <span
          className={classNames({
            active: urlStatus === SUCCESS,
          })}
        >
          Xano Endpoints
        </span>
        {renderEndpoints}
      </div>
    </>
  )
}

export const EndpointTestContent = ({
  label,
  type,
  path,
  url,
  isValid,
  error,
  entity,
}) => {
  const parsedTypeLabel = type.replace(/\s/g, '')

  return (
    <div className="endpoint-content">
      <div className="endpoint-signature">
        <span className="endpoint-label">{label}</span>
        <div
          className={classNames('signature-content', {
            error: !isValid,
          })}
        >
          <div>
            <span
              className={classNames('endpoint-type', {
                [parsedTypeLabel]: parsedTypeLabel,
              })}
            >
              {type.toUpperCase()}
            </span>
            <span className="endpoint-path">{path}</span>
            <p className="external-api-url">({url})</p>
          </div>
          {isValid ? <Icon type="done" color="teal" /> : null}
          {!isValid ? (
            <Icon className="flipped" type="info" color="orange" />
          ) : null}
        </div>
      </div>
      {!isValid && error ? (
        <div className="endpoint-response">
          <div className="title">
            {error?.message ? (
              <h3>{error.message}</h3>
            ) : (
              <h3>The endpoint "{path}" didn't match our requirements</h3>
            )}

            <Tooltip
              small
              hideArrow
              placement="top"
              tooltip={<span>Help Docs</span>}
              triggerClassname="help-docs"
            >
              <a
                href="https://help.adalo.com/integrations/xano"
                target="_blank"
                rel="noreferrer"
              >
                <Icon type="docs" />
              </a>
            </Tooltip>
          </div>
          <div>
            <span
              className={classNames({
                'error-message': true,
                active: error?.requestMissingFields,
              })}
            >
              Missing required field(s) in the request object:
              <ul>
                {error?.requestMissingFields?.map(field => (
                  <li>{field}</li>
                ))}
              </ul>
            </span>

            <span
              className={classNames({
                'error-message': true,
                active: error?.responseMissingFields,
              })}
            >
              Missing required field(s) in the response object:
              <ul>
                {error?.responseMissingFields?.map(field => (
                  <li>{field}</li>
                ))}
              </ul>
            </span>

            <span
              className={classNames({
                'error-message': true,
                active: error?.authMeErrorMessage,
              })}
            >
              {error.authMeErrorMessage}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  )
}

// TODO(dyego): replace with useDispatch/useSelector
const mapStateToProps = (state, { match }) => ({
  appId: match.params.appId,
})

export default withRouter(
  connect(mapStateToProps, {
    createCustomAction,
    updateApp,
    fetchCustomActions,
  })(ExternalUsersForm)
)

import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { dataTypes, sourceTypes, bindingTypes, TABLE } from '@adalo/constants'

import { THEMES, LAZY_LOADED_LISTS } from 'constants.js'

import { withPremiumFeatureHandler } from 'components/Shared/withPremiumFeatureHandler'

import {
  selectObject,
  getComponent,
  getCurrentAppId,
} from 'ducks/editor/objects'
import {
  getSimpleTableBindings,
  getListOptions,
  getSortOptions,
  getSources,
} from 'ducks/recommender'
import { getApp } from 'ducks/apps'
import { getDatasources, getTable } from 'ducks/apps/datasources'

import { buildBinding } from 'utils/bindings'
import { getLabel } from 'utils/sources'
import { verifyXanoApp } from 'utils/externalDatabases'

import MenuControl from '../Libraries/MenuControl'
import SimpleTextControl from '../Libraries/SimpleTextControl'
import NewTableOption from '../Libraries/NewTableOption'
import APIFilters from '../APIFilters'
import AdvancedListOptions from '../AdvancedListOptions'
import { FilterControl } from './FilterControl'
import { LocationControl } from './LocationControl'

function bindingComparator(val1, val2) {
  const source1 = val1 && val1.source
  const source2 = val2 && val2.source

  if (!source1 || !source2) {
    return false
  }

  return (
    source1.datasourceId === source2.datasourceId &&
    source1.tableId === source2.tableId
  )
}

function filterComparator(val1, val2) {
  val1 = { ...val1, id: null, sort: null }
  val2 = { ...val2, id: null, sort: null }

  return deepEqual(val1, val2)
}

// TODO(daniel): Refactor more of this component so the code looks better
const CommonListControl = props => {
  let {
    app,
    appId,
    componentId,
    object,
    datasource,
    displayName,
    value,
    tableOptions,
    filterOptions,
    sortOptions,
    locationOptions,
    getLabel,
    isCustomList,
    listControl,
    table,
    hideAdvancedOptions,
  } = props

  const isExternal = table?.type === 'api' || table?.type === 'xano'

  const handleChangeTable = ({ source }) => {
    const { name, onChange } = props

    const { componentName } = object

    const enablePaginate =
      LAZY_LOADED_LISTS.includes(componentName) || isCustomList
    const previousPaginate =
      typeof value?.options?.paginate === 'boolean' && value?.options?.paginate

    const paginate =
      enablePaginate !== previousPaginate ? enablePaginate : previousPaginate

    // New table
    if (source.source === undefined) {
      return
    }

    const isXano = datasource?.tables[source?.source?.tableId]?.type === 'xano'

    source = {
      ...source,
      options: {
        ...(typeof value?.options === 'object' ? value.options : {}),
        paginate: paginate && !isXano,
      },
      source: {
        ...source.source,
        sort: null,
      },
    }

    onChange({ [name]: source }, { trigger: 'table' })
  }

  const handleChangeFilter = ({ filter }) => {
    const { name, onChange } = props

    const sort = value && value.source && value.source.sort

    const newValue = {
      ...filter,
      source: {
        ...filter.source,
        sort,
      },
    }

    onChange({ [name]: newValue }, { trigger: 'filter' })
  }

  const handleChangeSort = newValue => {
    const { name, onChange } = props

    onChange(
      {
        [name]: {
          ...value,
          source: {
            ...value.source,
            ...newValue,
          },
        },
      },
      { trigger: 'sort' }
    )
  }

  const handleChangeLocationSource = sortReference => {
    const { name, onChange } = props
    const { source } = sortReference
    let binding = null

    delete value?.source?.sort?.reference?.source

    if (
      sortReference.source.type === sourceTypes.FIELD ||
      sortReference.source.type === sourceTypes.INPUT
    ) {
      binding = buildBinding(bindingTypes.SET_LOCATION, sortReference.source)
    }

    onChange(
      {
        [name]: {
          ...value,
          source: {
            ...value.source,
            sort: {
              ...value.source?.sort,
              reference: {
                fallback: value.source?.sort?.reference?.fallback,
                source: { ...source, binding },
              },
            },
          },
        },
      },
      { trigger: 'sort' }
    )
  }

  const handleChangeLocationFallback = fallback => {
    const { name, onChange } = props

    onChange(
      {
        [name]: {
          ...value,
          source: {
            ...value.source,
            sort: {
              ...value.source?.sort,
              reference: {
                ...value.source?.sort?.reference,
                fallback,
              },
            },
          },
        },
      },
      { trigger: 'sort' }
    )
  }

  const handleChangeSecondaryFilter = filter => {
    const { name, onChange } = props

    onChange({
      [name]: {
        ...value,
        source: {
          ...value.source,
          options: {
            ...value.source.options,
            filter,
          },
        },
      },
    })
  }

  const handleChangeLimit = ({ limit }) => {
    const { name, onChange } = props

    onChange({
      [name]: {
        ...value,
        source: {
          ...value.source,
          options: {
            ...value.source.options,
            limit,
          },
        },
      },
    })
  }

  const handleChangeAPIFilter = ({ queryParams }) => {
    const { name, onChange } = props

    onChange({
      [name]: {
        ...value,
        source: {
          ...value.source,
          options: {
            queryParams,
          },
        },
      },
    })
  }

  const handleBindingOptionChange = newValue => {
    const { name, onChange } = props

    return onChange({
      [name]: { ...value, options: { ...value?.options, ...newValue } },
    })
  }

  const addNew = tableOptions => {
    const newOption = {
      label: <NewTableOption handleChangeTable={handleChangeTable} />,
      value: {},
    }

    return tableOptions?.concat([null, newOption])
  }
  const boundTableRemoved = () => {
    if (!tableOptions) {
      return false
    }

    if (value?.source?.tableId) {
      const { tableId } = value.source

      return !tableOptions.find(t => t.value?.source?.tableId === tableId)
    }
  }

  const getLocationLabel = source => {
    const { getLocationLabel } = props

    if (source) {
      return getLocationLabel(source)
    }

    return ''
  }

  if (!listControl) {
    listControl = {
      filter: true,
      sort: true,
      limit: true,
      advancedOptions: true,
    }
  }

  // Check if table was removed
  if (boundTableRemoved()) {
    value = undefined
  }

  const { reference, ...sortValue } = value?.source?.sort || {}
  const fallbackValue = reference?.fallback || {}
  const { binding, ...locationValue } = reference?.source || {}

  const limitValue =
    value && value.source && value.source.options && value.source.options.limit

  if (value && value.source && value.source.collectionId) {
    return (
      <MenuControl
        options={tableOptions}
        name="source"
        displayName={displayName}
        value={value}
        onChange={handleChangeTable}
        comparator={bindingComparator}
        getLabel={getLabel}
      />
    )
  }

  let options = tableOptions

  const isXanoApp = verifyXanoApp(app)
  const isTableComponentObject = object.type === TABLE

  const showAddNew = datasource?.type !== 'api' && !isXanoApp // gsheet

  if (showAddNew) {
    options = addNew(tableOptions)
  }

  let controls = null
  if (value) {
    if (isExternal) {
      let advancedOptions = null
      if (listControl.advancedOptions && table?.type === 'xano') {
        if (!isTableComponentObject) {
          advancedOptions = (
            <AdvancedListOptions
              onChange={handleBindingOptionChange}
              value={value}
              isCustomList={isCustomList}
              componentName={object.componentName}
            />
          )
        } else {
          advancedOptions = <XanoTableHelpLink />
        }
      }

      controls = (
        <>
          <APIFilters
            onChange={handleChangeAPIFilter}
            value={value?.source?.options?.queryParams}
            name="queryParams"
            objectId={object.id}
          />
          {advancedOptions}
        </>
      )
    } else {
      const showFilter = listControl.filter
      const showSort = listControl.sort
      const showLocation =
        listControl.sort && sortValue && sortValue.type === dataTypes.LOCATION
      const showLimit = listControl.limit

      const showAdvanced = listControl.advancedOptions && !hideAdvancedOptions

      controls = (
        <>
          {showFilter && (
            <FilterControl
              filterOptions={filterOptions}
              value={value}
              getLabel={getLabel}
              handleChangeFilter={handleChangeFilter}
              filterComparator={filterComparator}
              handleChangeSecondaryFilter={handleChangeSecondaryFilter}
              object={object}
              appId={appId}
              componentId={componentId}
            />
          )}
          {showSort && (
            <MenuControl
              options={sortOptions}
              name="sort"
              displayName="Sorting"
              value={sortValue}
              onChange={handleChangeSort}
              comparator={deepEqual}
              placeholder="Add sorting..."
              menuTheme={THEMES.DATA}
            />
          )}
          {showLocation && (
            <LocationControl
              fallbackValue={fallbackValue}
              locationOptions={locationOptions}
              getLocationLabel={getLocationLabel}
              locationValue={locationValue}
              handleChangeLocationSource={handleChangeLocationSource}
              handleChangeLocationFallback={handleChangeLocationFallback}
              appId={appId}
            />
          )}
          {showLimit && (
            <SimpleTextControl
              inputType="number"
              name="limit"
              value={limitValue}
              onChange={handleChangeLimit}
              displayName="Maximum number of items"
              placeholder="No Maximum"
            />
          )}
          {showAdvanced && (
            <AdvancedListOptions
              onChange={handleBindingOptionChange}
              value={value}
              isCustomList={isCustomList}
              componentName={object.componentName}
            />
          )}
        </>
      )
    }
  }

  return (
    <div id="data-menu-options">
      <MenuControl
        options={options}
        name="source"
        displayName={displayName === 'select' ? 'Menu Options' : displayName}
        value={value}
        onChange={handleChangeTable}
        comparator={bindingComparator}
        menuTheme={THEMES.DATA}
      />
      {controls}
    </div>
  )
}

const XanoTableHelpLink = () => (
  <a
    href="https://help.adalo.com/integrations/xano/lists-and-pagination"
    rel="noreferrer"
    target="_blank"
    style={{ display: 'block', margin: '8px auto' }}
  >
    Learn to set up Xano to work with Tables.
  </a>
)

const mapStateToProps = (state, { objectId, value, helpers }) => {
  const appId = getCurrentAppId(state)
  const app = getApp(state, appId)
  const componentId = getComponent(state, objectId)?.id
  const object = selectObject(state, objectId)
  let filterOptions = []
  let sortOptions = []
  let locationOptions = []

  const datasource = getDatasources(state, appId)[0]

  if (value) {
    filterOptions = getListOptions({
      state,
      appId,
      componentId,
      objectId,
      object,
      binding: value,
    })

    sortOptions = getSortOptions({
      state,
      appId,
      object,
      binding: value,
    })

    locationOptions = getSources(
      state,
      appId,
      componentId,
      objectId,
      [dataTypes.LOCATION],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
      helpers
    )
  }

  return {
    app,
    appId,
    componentId,
    datasource,
    filterOptions,
    object,
    sortOptions,
    table: getTable(
      state,
      appId,
      value?.source?.datasourceId,
      value?.source?.tableId
    ),
    tableOptions: getSimpleTableBindings(state, appId, helpers),
    getLabel: binding => {
      return binding && getLabel(state, binding.source, appId, componentId)
    },
    locationOptions,
    getLocationLabel: source =>
      getLabel(state, source, appId, componentId, true),
  }
}

export default withPremiumFeatureHandler(
  withRouter(
    connect(mapStateToProps, { getDatasources, getTable })(CommonListControl)
  )
)

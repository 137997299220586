import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { getTrialInformation, getUpgraded } from 'ducks/apps'
import { getDatasource, getTable } from 'ducks/apps/datasources'
import { getMetrics } from 'ducks/metrics'

import { getIsOverLimit } from 'utils/metrics'

import {
  APICollection,
  CSVImport,
  CreateObjectModal,
  Table,
  WrappedCollection,
} from 'components/AppDetail/Data'

const Modal = props => {
  const { datasource, table, match, metrics, paying } = props
  const { appId, datasourceId, tableId } = match.params

  const fields = { appId, datasourceId, id: tableId }
  const TableFields = { ...fields, datasource, table }
  const APIFields = { ...fields, datasource }

  if (!datasource) return null

  const isOverLimit = getIsOverLimit(metrics, datasource)
  const disabled = isOverLimit && !paying

  return (
    <WrappedCollection {...props}>
      <Switch>
        {['apto-backend', 'gsheet'].includes(datasource.type) ? (
          <Route
            exact
            path="/apps/:appId/data/:datasourceId/:tableId"
            render={() => <Table {...TableFields} />}
          />
        ) : datasource.type === 'api' ? (
          <Route
            exact
            path="/apps/:appId/data/:datasourceId/:tableId"
            render={() => <APICollection {...APIFields} />}
          />
        ) : null}
        {disabled ? (
          <Redirect to="/apps/:appId/data/:datasourceId/:tableId" />
        ) : (
          <Route
            exact
            path="/apps/:appId/data/:datasourceId/:tableId/new"
            component={CreateObjectModal}
          />
        )}
        <Route
          exact
          path="/apps/:appId/data/:datasourceId/:tableId/edit/:id"
          component={CreateObjectModal}
        />
        <Route
          exact
          path="/apps/:appId/data/:datasourceId/:tableId/csv-import/"
          component={CSVImport}
        />
      </Switch>
    </WrappedCollection>
  )
}

const mapStateToProps = (state, { match }) => {
  const { appId, datasourceId, tableId } = match.params
  const { trialState } = getTrialInformation(state, appId)

  const paying = getUpgraded(state, appId) || trialState === 'during'

  return {
    datasource: getDatasource(state, appId, datasourceId),
    table: getTable(state, appId, datasourceId, tableId),
    metrics: getMetrics(state, appId),
    paying,
  }
}

export default connect(mapStateToProps)(Modal)
